import { useTheme } from '@mui/material';

const MatchFundingContentPricingTableData = (timetablePrice) => {
  const theme = useTheme();

  const poundsAndPennies = (val) => {
    if (val === 0) {
      return '£0';
    }
    return `£${val.toFixed(2)}`;
  };

  const headers = [
    '',
    'School Funded Places',
    '= Free Bursary Places',
    'Total Places',
    'Monthly Cost',
    'Cost Per Child'
  ];

  const borderColor = theme.palette.whiteWedding.main;
  const columnColors = [
    {
      headerBgColor: theme.palette.whiteWedding.main,
      bgColor: theme.palette.whiteWedding.main,
      textColor: theme.palette.whiteWedding.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.prettyInPink.main,
      bgColor: theme.palette.prettyInPink[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.greenOnions.main,
      bgColor: theme.palette.greenOnions[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    }
  ];
  const baseRowHighlight = {
    bgColor: theme.palette.mrBlueSky[150],
    textColor: theme.palette.whiteWedding.main
  };

  const data = [...Array(11)].map((_, i) => {
    const fundedPlaces = i;
    const bursaryPlaces = fundedPlaces === 0 ? 1 : i; // We give 1 bursary spot for free, otherwise match the places
    return [
      '',
      fundedPlaces.toString(),
      bursaryPlaces.toString(),
      (fundedPlaces + bursaryPlaces).toString(),
      poundsAndPennies(parseFloat(timetablePrice * fundedPlaces)),
      poundsAndPennies(fundedPlaces === 0 ? 0 : parseFloat(timetablePrice / 2))
    ];
  });

  // last row
  const fullyFunded = parseFloat((timetablePrice * 21) / 45);
  data.push([
    'Fully Funded Half Day',
    '21',
    '24',
    '45',
    poundsAndPennies(parseFloat(fullyFunded * 45)),
    poundsAndPennies(fullyFunded)
  ]);

  return {
    headers,
    borderColor,
    columnColors,
    baseRowHighlight,
    data
  };
};

export default MatchFundingContentPricingTableData;
