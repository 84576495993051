import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { camelCase, isEmpty, snakeCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { Card, Page } from '@rocksteady-music-school/rms-ui';
import FeelGoodFridayAPI from '../api/FeelGoodFridayAPI';
import FGFFooter from '../components/FeelGoodFriday/FGFFooter';
import FGFHeader from '../components/FeelGoodFriday/FGFHeader';
import lessonsImg from '../assets/images/AutomatedEmailList.png';
import shoutOutImg from '../assets/images/ManageSchoolFunded.png';
import { useUserData } from '../contexts/UserContext';
import paths from '../consts/paths';
import WindowUtils from '../utils/WindowUtils';
import response from '../consts/actions';
import useIsMounted from '../hooks/useIsMounted';
import ROCKSTEADY_AGE_GROUPS from '../consts/rocksteadyAgeGroups';
import FGFEpisodeContent from '../components/FeelGoodFriday/FGFEpisodeContent';
import FGFNavigator from '../components/FeelGoodFriday/FGFNavigator';

export const shareFGFViaEmail = () => {
  const subjectLine = 'Access Feel Good Friday music lessons';
  const body = `Dear colleague,
  %0D%0A%0D%0AI think you would be interested in accessing Rocksteady's Feel Good Friday video music lessons. Create an account or log in, if you already have an account, and take a look for yourself.
  %0D%0A%0D%0AAccess Feel Good Friday: https://portal.rocksteadymusicschool.com/feel-good-friday`;

  return WindowUtils.replaceLocation(`mailto:?subject=${subjectLine}&body=${body}`);
};

const tabEntries = Object.entries(ROCKSTEADY_AGE_GROUPS).map(
  ([name, { years, color, plural }]) => ({
    title: years,
    name,
    color,
    plural
  })
);

const filterEpisodesByAgeGroup = (episodes, ageGroup) =>
  episodes.filter(({ attributes }) => attributes.age_group === ageGroup);

// Will construct object like: { miniRocker: [], rockHero: [], rockIcon: [] }
const partitionEdisodesByAgeGroup = (episodes) =>
  Object.keys(ROCKSTEADY_AGE_GROUPS).reduce(
    (o, key) => ({ ...o, [key]: filterEpisodesByAgeGroup(episodes, snakeCase(key)) }),
    {}
  );

export default function FeelGoodFridayPage() {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { isBasic, isActiveSchool, isStaff } = useUserData();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeAgeGroup =
    (ROCKSTEADY_AGE_GROUPS[camelCase(searchParams.get('age_group'))] &&
      camelCase(searchParams.get('age_group'))) ||
    Object.keys(ROCKSTEADY_AGE_GROUPS)[0]; // default to miniRocker

  const publicationDateUrlParam = searchParams.get('publication_date');

  const handleTabSelect = (ageGroup) => {
    setSearchParams((prev) => {
      prev.set('age_group', snakeCase(ageGroup));
      return prev;
    });
  };

  const [episodes, setEpisodes] = useState({}); // episodes are served to us in publication_date order, desc.
  const [fetchStatus, setFetchStatus] = useState(null);

  const activeAgeGroupEpisodes = episodes[activeAgeGroup] || [];
  const activeAgeGroupEpisodeDates = useMemo(
    () =>
      activeAgeGroupEpisodes.map(({ attributes }) =>
        dayjs(attributes.publication_date).startOf('d')
      ),
    [activeAgeGroupEpisodes]
  );

  const findEpisodeIndex = (date) => {
    const queriedDate = dayjs(date).startOf('d');
    return activeAgeGroupEpisodeDates.findIndex((d) => !d.diff(queriedDate));
  };

  const activeEpisodeIndex = () => {
    const index = findEpisodeIndex(publicationDateUrlParam || dayjs().day(5).format('YYYY-MM-DD'));
    const latestIndex = findEpisodeIndex(dayjs().day(5).format('YYYY-MM-DD'));
    switch (index) {
      case 0:
        return 0;
      case -1:
        return latestIndex === -1 ? 0 : latestIndex;
      default:
        return index;
    }
  };

  const activeEpisode = activeAgeGroupEpisodes[activeEpisodeIndex()];
  const activeEpisodeDate = activeAgeGroupEpisodeDates[activeEpisodeIndex()];

  const handleDateSelect = (date) => {
    setSearchParams((prev) => {
      prev.set('publication_date', date.format('YYYY-MM-DD'));
      return prev;
    });
  };

  const handleSelectPrevious = () =>
    handleDateSelect(activeAgeGroupEpisodeDates[activeEpisodeIndex() + 1]);
  const handleSelectNext = () =>
    handleDateSelect(activeAgeGroupEpisodeDates[activeEpisodeIndex() - 1]);

  useEffect(() => {
    // We cannot guarantee parity between age-group series, so unless a newly selected age-group
    // has a video published on same date as previous, just go to this weeks episode or the latest episode..
    if (activeAgeGroupEpisodes.length && publicationDateUrlParam) {
      const index = findEpisodeIndex(publicationDateUrlParam);
      if (index === -1) {
        const baseIndex = findEpisodeIndex(dayjs().day(5).format('YYYY-MM-DD'));
        const indexToUse = baseIndex === -1 ? 0 : baseIndex;
        handleDateSelect(activeAgeGroupEpisodeDates[indexToUse]); // go to this weeks episode or the latest episode.
        enqueueSnackbar('Sorry, an episode for the given date could not be found', {
          variant: 'warning',
          autoHideDuration: 5000
        });
      }
    }
  }, [activeAgeGroupEpisodes, searchParams]);

  useEffect(() => {
    setFetchStatus(response.LOADING);
    FeelGoodFridayAPI.getFeelGoodFridayData()
      .then((res) => {
        if (isMounted()) {
          setEpisodes(partitionEdisodesByAgeGroup(res.data.data));
          setFetchStatus(response.SUCCESS);
        }
      })
      .catch(() => setFetchStatus(response.ERROR));
  }, []);

  const downloadCaseStudies = () => WindowUtils.replaceLocation(paths.CASE_STUDIES);

  const rightCard = () => {
    if (!isActiveSchool) {
      return (
        <Card
          buttonProps={{ label: 'Find Out More', href: paths.BAND_LESSONS }}
          altText="Rocksteady band playing instruments"
          imgRight
          summary="We also offer peripatetic rock and pop band lessons for schools, under the guidance of your very own Rocksteady Band Leader."
          thumbnail={lessonsImg}
          title="Rocksteady in-school band lessons"
        />
      );
    }
    if (isStaff || isBasic) {
      return (
        <Card
          altText="Rocksteady band playing instruments"
          buttonProps={{ label: 'Check Out Our Case Studies', onClick: downloadCaseStudies }}
          imgRight
          summary="Read case studies about how Rocksteady lessons can support children in different circumstances."
          thumbnail={lessonsImg}
          title="Case Studies"
        />
      );
    }
    return (
      <Card
        altText="Rocksteady band playing instruments"
        buttonProps={{
          label: 'Use Your Free Bursary Place',
          href: paths.MATCH_FUNDING
        }}
        imgRight
        summary="Rocksteady match every School Funded Space with a Rocksteady Bursary. What's more, the first Bursary space is provided up-front."
        thumbnail={lessonsImg}
        title="Support more children through Match-Funding"
      />
    );
  };

  const cards = () => (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      gap={{ xs: 32, xl: 64 }}
      marginY={32}>
      <Card
        altText="Rocksteady band playing instruments"
        buttonProps={{ label: 'Share FGF', onClick: shareFGFViaEmail }}
        imgRight
        summary="Click to send info about Feel Good Friday to another teacher who loves music."
        thumbnail={shoutOutImg}
        title="Share Feel Good Friday"
      />
      {rightCard()}
    </Box>
  );

  return (
    <Page error={fetchStatus === response.ERROR}>
      <FGFHeader />
      <FGFNavigator
        dates={activeAgeGroupEpisodeDates}
        activeDate={activeEpisodeDate}
        onPrevSelect={handleSelectPrevious}
        onNextSelect={handleSelectNext}
        onDateSelect={handleDateSelect}
        onTabSelect={handleTabSelect}
        tabEntries={isEmpty(episodes) ? [] : tabEntries}>
        <FGFEpisodeContent
          bgColor={ROCKSTEADY_AGE_GROUPS[activeAgeGroup].color}
          data={activeEpisode?.attributes}
          loading={fetchStatus !== response.SUCCESS}
          key={activeAgeGroup}
        />
      </FGFNavigator>
      {cards()}
      <FGFFooter />
    </Page>
  );
}
