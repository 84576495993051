import { Box, Grid, Typography } from '@mui/material';
import MiniDashboard from '../MiniDashboard/MiniDashboard';
import ContactsCard from '../MiniDashboard/ContactsCard';

export default function HomePageHeader({ setFetchError }) {
  const title = () => (
    <Typography
      variant="h1"
      color="fadeToGrey.main"
      mb={16}
      sx={{ whiteSpace: 'pre-line', display: 'flex', flexGrow: 1, alignItems: 'center' }}>
      Welcome to your schools portal
    </Typography>
  );

  const contactsLink = () => (
    <Box width={{ xs: '100%', sm: 246.5 }} height={70} display="grid">
      <ContactsCard />
    </Box>
  );

  const leftGridItem = () => (
    <Grid
      item
      xs={12}
      lg={5}
      mb={{ xs: 20, lg: 0 }}
      display="flex"
      flexDirection="column"
      alignSelf="stretch">
      {title()}
      {contactsLink()}
    </Grid>
  );

  const pageHeader = () => (
    <Grid
      container
      columnSpacing={20}
      mt={{ xs: 0, md: 24 }}
      mb={{ xs: 40, sm: 68 }}
      display="flex"
      alignItems="center">
      {leftGridItem()}
      <MiniDashboard setFetchError={setFetchError} />
    </Grid>
  );

  return pageHeader();
}
