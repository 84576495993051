/* eslint-disable no-use-before-define */
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Button } from '@rocksteady-music-school/rms-ui';
import { useAuth } from '../../contexts/AuthContext';
import StringUtils from '../../utils/StringUtils';
import SchoolAPI from '../../api/SchoolAPI';
import externalPaths from '../../consts/externalPaths';

const SignUpForm = ({ signUpValues, setSignUpValues }) => {
  const { signUp } = useAuth();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [failedSignUp, setFailedSignUp] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [schools, setSchools] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [schoolFetchError, setSchoolFetchError] = useState(false);

  const { email, firstName, lastName, role, school, optedInForMarketing } = signUpValues;

  const emailValid = () => StringUtils.isEmailIsh(email);

  const formValid = () => emailValid() && firstName && lastName && role && school && termsAccepted;

  const emailInvalid = showValidationErrors && !emailValid();

  const valueMissing = (name) => {
    if (!showValidationErrors) return false;

    if (!signUpValues[name]) return true;

    // Check that it contains something other than whitespace
    return !signUpValues[name].trim();
  };

  const mapResponseToOptions = (data) =>
    // Filter out closed schools!
    // This is seen to be a temporary solution before spending time amending search-service-class
    // server-side. We can be confident based on the Abbey Road solution that closed schools have
    // "(Closed)" appended to the end of the name string.
    data
      .filter(({ name }) => !name.includes('(Closed)', name.length - 8))
      .map((item) => ({
        key: item.id,
        urn: item.id.toString(),
        name: item.name
      }));

  const search = (query) => {
    setSchoolFetchError(false);
    SchoolAPI.findSchool(query)
      .then((res) => {
        setSchools(mapResponseToOptions(res.data));
      })
      .catch(() => setSchoolFetchError(true));
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    if (query.length > 2) {
      search(query);
    } else {
      setSchools([]);
    }
  };

  const firstNameField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="first-name"
      label="First Name"
      name="firstName"
      autoComplete="given-name"
      autoFocus
      onChange={handleInputChange}
      error={valueMissing('firstName')}
      helperText={valueMissing('firstName') ? 'Please enter your first name' : ''}
      sx={{ margin: 0 }}
    />
  );

  const lastNameField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="last-name"
      label="Last Name"
      name="lastName"
      autoComplete="family-name"
      onChange={handleInputChange}
      error={valueMissing('lastName')}
      helperText={valueMissing('lastName') ? 'Please enter your last name' : ''}
      sx={{ margin: 0 }}
    />
  );

  const emailField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      type="email"
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter your school email address' : ''}
      sx={{ width: '80%' }}
    />
  );

  const roleField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="role"
      label="Role"
      name="role"
      autoComplete="role"
      onChange={handleInputChange}
      error={valueMissing('role')}
      helperText={valueMissing('role') ? 'Please enter your role' : ''}
      sx={{ width: '80%' }}
    />
  );

  const schoolField = () => (
    <Autocomplete
      id="schools-search"
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      options={schools}
      onInputChange={(event, newInputValue) => handleSearchChange(newInputValue)}
      isOptionEqualToValue={(option, value) => option.urn === value.urn}
      onChange={(event, newValue) => handleSelectingSchool(newValue)}
      noOptionsText={message()}
      onBlur={() => setSchoolFetchError(false)}
      sx={{ width: '80%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          required
          label="School"
          name="school"
          error={valueMissing('school') || schoolFetchError}
          helperText={valueMissing('school') ? 'Please choose a school' : ''}
        />
      )}
    />
  );

  const message = () => {
    if (searchQuery.length < 3) {
      return 'Type school name / postcode to search...';
    }

    if (schoolFetchError) return 'Error fetching schools';

    return 'No results found';
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const consideredValue = type === 'checkbox' ? checked : value;
    setShowValidationErrors(false);
    setSignUpValues({ ...signUpValues, [name]: consideredValue });
  };

  const handleSelectingSchool = (option) =>
    setSignUpValues({ ...signUpValues, school: option?.urn || '' });

  const handleSubmit = (event) => {
    event.preventDefault();

    setShowValidationErrors(true);

    if (formValid()) {
      setSignUpLoading(true);
      signUp(
        firstName,
        lastName,
        email,
        role,
        school,
        optedInForMarketing,
        setSignUpLoading,
        setFailedSignUp,
        setErrorMsg
      );
    }
  };

  const marketingOptInCheckbox = () => (
    <FormControl component="fieldset" sx={{ display: 'block' }} variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={optedInForMarketing}
              onChange={handleInputChange}
              name="optedInForMarketing"
            />
          </div>
        }
        label={
          <Typography variant="body">
            I would like to receive updates about new resources available, including Feel Good{' '}
            Friday.
          </Typography>
        }
      />
    </FormControl>
  );

  const tcCheckbox = () => (
    <FormControl
      required
      error={showValidationErrors && !termsAccepted}
      component="fieldset"
      sx={{ display: 'block' }}
      variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              name="terms"
            />
          </div>
        }
        label={
          <Typography variant="body" component="p">
            I agree to the{' '}
            <a href={externalPaths.PRIVACY_POLICY} target="_blank" rel="noreferrer">
              <Typography variant="bodyLink">privacy policy</Typography>
            </a>{' '}
            and the{' '}
            <a href={externalPaths.TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
              <Typography variant="bodyLink">terms and conditions</Typography>
            </a>
            .
          </Typography>
        }
      />
      {showValidationErrors && !termsAccepted && (
        <FormHelperText>Please accept the terms first</FormHelperText>
      )}
    </FormControl>
  );

  const renderErrorAlert = () => (
    <Alert severity="error" sx={styles.errorAlert}>
      {Array.isArray(errorMsg) ? errorMsg.join('. ') : errorMsg}
    </Alert>
  );

  const signUpForm = () => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Stack spacing={19} mt={27} mb={10} sx={styles.formGroup}>
          {failedSignUp && renderErrorAlert()}
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            {firstNameField()}
            {lastNameField()}
          </Stack>

          {emailField()}
          {roleField()}
          {schoolField()}
        </Stack>
        {tcCheckbox()}
        {marketingOptInCheckbox()}

        <Button
          type="submit"
          label="Create an account"
          style={styles.submitBtn}
          loading={signUpLoading}
          color="secondary"
        />
      </Box>
    );
  };

  return signUpForm();
};

const styles = {
  formGroup: { width: { lg: '75%', md: '75%', xs: '100%' } },
  passwordLink: { mb: 20, mt: 12 },
  submitBtn: { width: 'fit-content', marginTop: 24, marginBottom: 23 },
  errorAlert: { mb: 5 }
};

export default SignUpForm;
