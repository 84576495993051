/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */

import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Carousel({
  slides,
  textColor,
  backgroundImg,
  textFontStyle,
  authorFontStyle,
  authorTextColor,
  activeIndicatorColor,
  inactiveIndicatorColor,
  indicatorHoverColor
}) {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}>
        {slides.map((slide) => (
          <Box key={uuidv4()}>
            <Box sx={{ ...styles.quotation, backgroundImage: `url(${backgroundImg})` }}>
              <Typography
                textAlign="center"
                component="p"
                variant={textFontStyle}
                color={textColor}
                mb={8}>
                {slide.text}
              </Typography>
            </Box>
            <Typography
              textAlign="center"
              component="p"
              variant={authorFontStyle}
              color={authorTextColor}>
              {slide.author}
            </Typography>
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <Box sx={styles.progressBar}>
        {[...Array(slides.length).keys()].map((i) => (
          <Box
            data-testid="indicator"
            key={uuidv4()}
            sx={{
              ...styles.indicator,
              '&:hover':
                activeStep !== i
                  ? {
                      backgroundColor: indicatorHoverColor,
                      cursor: 'pointer'
                    }
                  : null,
              backgroundColor: activeStep === i ? activeIndicatorColor : inactiveIndicatorColor
            }}
            onClick={() => handleStepChange(i)}
          />
        ))}
      </Box>
    </Box>
  );
}

const styles = {
  progressBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10
  },
  indicator: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    marginRight: '8px',
    transition: 'background-color 250ms ease-out'
  },
  quotation: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
