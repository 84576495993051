/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Card, Page } from '@rocksteady-music-school/rms-ui';
import TimetableAPI from '../api/TimetableAPI';
import BandsContainer from '../components/BandsContainer/BandsContainer';
import TimetableContainer from '../components/TimetableContainer/TimetableContainer';
import HomePageHeader from '../components/HomePageHeader/HomePageHeader';
import { useUserData } from '../contexts/UserContext';
import bursaryKids from '../assets/images/AutomatedEmailList.png';
import fundedKids from '../assets/images/ManageSchoolFunded.png';
import paths from '../consts/paths';

export default function TimetablePage() {
  const { isAdmin, isAdminOrStaff, isActiveSchool, timetables } = useUserData();

  const [selectedTT, setSelectedTT] = useState(timetables[0]);
  const [bandsDataLoading, setBandsDataLoading] = useState(true);
  const [bandsData, setBandsData] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const getBandsData = (id) => {
    setBandsDataLoading(true);

    TimetableAPI.getBandsData(id)
      .then((res) => {
        setBandsData(res.data);
        setBandsDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  useEffect(() => {
    if (selectedTT?.id) getBandsData(selectedTT.id);
  }, [selectedTT]);

  const handleTTChange = (event) => {
    setSelectedTT(event.target.value);
  };

  const showTTDropdown = isActiveSchool && isAdminOrStaff && timetables?.length > 1;

  const ttDropdown = () => (
    <FormControl fullWidth>
      <InputLabel id="select-tt-label">Select Timetable</InputLabel>
      <Select
        labelId="select-tt-label"
        id="select-tt"
        value={selectedTT}
        label="Select Timetable"
        onChange={handleTTChange}>
        {timetables.map((tt) => (
          <MenuItem key={tt.id} value={tt}>
            {tt.display_day_time_slot}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const timetableContent = () => {
    if (selectedTT?.id) {
      return (
        <TimetableContainer
          id={selectedTT.id}
          ttDayTimeSlot={selectedTT.display_day_time_slot}
          bandsData={bandsData}
          bandsDataLoading={bandsDataLoading}
          setFetchError={setFetchError}
        />
      );
    }
    return null;
  };

  const bandsContent = () => {
    if (selectedTT?.id) {
      return <BandsContainer bandsData={bandsData} bandsDataLoading={bandsDataLoading} />;
    }
    return null;
  };

  const bursaryAndFundedCards = () =>
    isAdmin && (
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 32, xl: 64 }}
        mt={32}>
        <Card
          altText="Rocksteady band playing instruments"
          buttonProps={{ label: 'Use Your Free Bursary Place', href: paths.MATCH_FUNDING }}
          imgRight
          summary="Rocksteady match every School Funded Space with a Rocksteady Bursary. What's more, the first Bursary space is provided up-front."
          thumbnail={bursaryKids}
          title="Support more children through Match-Funding"
        />
        <Card
          altText="Rocksteady band playing instruments"
          buttonProps={{ label: 'View Your Impact Report', href: paths.IMPACT_REPORT }}
          imgRight
          summary="Connect with your pupils exciting musical journey and access instant stats on the impact Rocksteady is having in your school."
          thumbnail={fundedKids}
          title="IMPACT REPORT AND SCHOOL TIMELINE"
        />
      </Box>
    );

  return (
    <Page error={fetchError}>
      <HomePageHeader setFetchError={setFetchError} />
      <Box width={{ xs: '100%', sm: 266 }} mb={24}>
        {showTTDropdown && ttDropdown()}
      </Box>
      {timetableContent()}
      {bandsContent()}
      {bursaryAndFundedCards()}
    </Page>
  );
}
