/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Page } from '@rocksteady-music-school/rms-ui';
import SchoolAPI from '../api/SchoolAPI';
import MatchFundingContent from '../components/MatchFundingContent/MatchFundingContent';
import response from '../consts/actions';
import contactDetails from '../consts/contactDetails';
import paths from '../consts/paths';
import { useUserData } from '../contexts/UserContext';
import usePageVisitLogger from '../hooks/usePageVisitLogger';

export default function MatchFundingPage() {
  usePageVisitLogger();
  const { schoolUrn, timetables } = useUserData();

  const [matchedFundingData, setMatchFundingData] = useState({});
  const [fetchStatus, setFetchStatus] = useState(null);

  const { bursary_kids: bursaryKids, school_funded_kids: schoolFundedKids } = matchedFundingData;

  const numOfKids = (bursaryKids?.length || 0) + (schoolFundedKids?.length || 0);

  const dataHasBeenFetched = fetchStatus === response.SUCCESS;

  useEffect(() => {
    setFetchStatus(response.LOADING);
    SchoolAPI.getMatchFundingData(schoolUrn)
      .then((res) => {
        setMatchFundingData(res.data);
        setFetchStatus(response.SUCCESS);
      })
      .catch(() => {
        setFetchStatus(response.ERROR);
      });
  }, []);

  const timetableRate = useMemo(() => {
    if (timetables?.length) {
      const highestTimetableRate = Math.max(...timetables.map((t) => t.rate_in_pennies));
      return highestTimetableRate / 1.2; // 1.2 so not inc VAT
    }
    return null;
  }, [timetables]);

  const bannerTitle = () => {
    if (numOfKids > 0) {
      return `Thank you for supporting ${
        numOfKids > 1 ? `${numOfKids} pupils` : '1 pupil'
      } through the match-funding scheme`;
    }
    return "You currently don't have any pupils enrolled in the match-funding scheme";
  };

  const bannerTitleColor = () => {
    if (numOfKids) {
      return 'greenOnions.140';
    }
    return 'mellowYellow.140';
  };

  const bannerDescription = () => {
    if (numOfKids) {
      return (
        <Typography variant="body">
          To support even more pupils at your school use the form below or contact the{' '}
          <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
            <Typography variant="bodyLink">Schools Hotline</Typography>
          </a>{' '}
          or call{' '}
          <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
            <Typography variant="bodyLink">{contactDetails.SCHOOLS_TEL}</Typography>
          </a>{' '}
          to discuss further.
        </Typography>
      );
    }
    return (
      <Typography variant="body">
        Use the form below to get started or contact the{' '}
        <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
          <Typography variant="bodyLink">Schools Hotline</Typography>
        </a>{' '}
        or call{' '}
        <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
          <Typography variant="bodyLink">{contactDetails.SCHOOLS_TEL}</Typography>
        </a>{' '}
        to discuss further.
      </Typography>
    );
  };

  const placesInfo = {
    title: bannerTitle(),
    color: bannerTitleColor(),
    description: bannerDescription(),
    kids: matchedFundingData,
    numOfKids
  };

  const description = () => (
    <Typography component="p" variant="body">
      Through our Match-Funding Scheme you can ensure the children who would benefit most are able
      to take part. Rocksteady match every School Funded Space with a Rocksteady Bursary. For
      example, if your school funds two places, a total of four pupils will benefit from the
      programme! What’s more, to help schools see the huge impact Rocksteady can have on your most
      disadvantaged pupils, Rocksteady provide the first Bursary space up-front.
      <br />
      <br />
      Rocksteady lessons can support children in many different ways. You can find out more{' '}
      <a href={paths.CASE_STUDIES} target="_blank" rel="noopener noreferrer">
        <Typography variant="bodyLink">here</Typography>
      </a>
      . Simply submit every child that you want to take part and we can take care of everything
      else.
    </Typography>
  );

  return (
    <Page error={fetchStatus === response.ERROR}>
      <MatchFundingContent
        placesInfo={placesInfo}
        description={description()}
        loading={!dataHasBeenFetched}
        timetableRate={timetableRate}
      />
    </Page>
  );
}
