/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import SchoolAPI from '../../api/SchoolAPI';
import { useUserData } from '../../contexts/UserContext';
import ConcertCard from './ConcertCard';
import FgfCard from './FgfCard';
import FundingCards from './FundingCards';

function MiniDashboardContainer({ children }) {
  return (
    <Grid
      item
      xs={12}
      lg={7}
      display={{ lg: 'flex' }}
      justifyContent="flex-end"
      alignSelf="flex-start">
      <Box p={16} width={{ lg: 500 }} sx={styles.miniDashboardContainer}>
        {children}
      </Box>
    </Grid>
  );
}

export default function MiniDashboard({ setFetchError }) {
  const { isAdminOrStaff, isBasic, isActiveSchoolAdminOrStaff, isActiveSchool, schoolUrn } =
    useUserData();

  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (schoolUrn) {
      setLoading(true);
      SchoolAPI.getSchoolDashboardData(schoolUrn)
        .then((res) => {
          setDashboardData(res.data);
          setLoading(false);
        })
        .catch(() => setFetchError(true));
    }
  }, []);

  const fundingCards = () =>
    isActiveSchoolAdminOrStaff && <FundingCards fundingData={dashboardData} loading={loading} />;

  const usefulInfoCards = () => (
    <>
      {isActiveSchool && (
        <ConcertCard nextConcertDate={dashboardData.nextConcertDate} loading={loading} />
      )}
      {isAdminOrStaff && <FgfCard />}
    </>
  );

  const miniDashboard = () => (
    <MiniDashboardContainer>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridAutoRows="1fr"
        gridColumn="span 2"
        gap={7}>
        {fundingCards()}
        {usefulInfoCards()}
      </Box>
    </MiniDashboardContainer>
  );

  // For now if it's a non-active school basic user, don't show the dashboard at all
  // Once we implement notifications, we will show the dashboard, but just for notifications
  return !isActiveSchool && isBasic ? null : miniDashboard();
}

const styles = {
  miniDashboardContainer: {
    background: 'rgba(0, 114, 206, 0.1)',
    borderRadius: '20px'
  }
};
