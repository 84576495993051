/* eslint-disable react/prop-types */
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Grid, Typography } from '@mui/material';
import { Modal } from '@rocksteady-music-school/rms-ui';
import userRoles from '../../consts/userRoles';
import PermissionsCard from '../PermissionsCard/PermissionsCard';
import StringUtils from '../../utils/StringUtils';

export default function PermissionChangeModal({
  currentRole,
  closeModal,
  onCancelRequest,
  onNewRequest,
  pendingRequest,
  loading
}) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

  const requestReceivedText =
    'We have received your request and are working on it. If more details are needed, our schools-support team will be in touch.';

  const openConfirmCancelDialog = () => setConfirmCancelDialogOpen(true);
  const closeConfirmCancelDialog = () => setConfirmCancelDialogOpen(false);

  const onConfirmCancelRequest = () => {
    closeConfirmCancelDialog();
    onCancelRequest(pendingRequest.id);
  };

  const onConfirmNewRequest = () => onNewRequest(selectedRole);

  const renderInstructionalText = () => (
    <Typography variant="body" mb={16}>
      If you wish to change your site access permissions, please select the access level you require{' '}
      and submit your request. Our schools team will advise you when your access permissions have{' '}
      been changed.
    </Typography>
  );

  const renderPermissionsCards = () => (
    <Box>
      {Object.values(userRoles).map(({ roleName, roleAbilities }) => (
        <Box mt={8} key={uuidv4()}>
          <PermissionsCard
            roleName={roleName}
            showButtons
            abilities={roleAbilities}
            isCurrent={currentRole === roleName}
            isSelected={selectedRole === roleName}
            setIsSelected={setSelectedRole}
          />
        </Box>
      ))}
    </Box>
  );

  const renderRequestReceivedText = () => (
    <Typography variant="body" mb={16}>
      {requestReceivedText}
    </Typography>
  );

  const pendingRequestTableRow = (title, value) => (
    <Grid container display="flex">
      <Grid item xs={6}>
        <Typography variant="body" display="block" mt={8}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="bodyBold" display="block" mt={8}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderPendingRequestTable = () => {
    const {
      attributes: {
        role_requested: roleRequested,
        requested_at: requestedAt,
        role_at_time_of_request: roleAtTimeOfRequest
      }
    } = pendingRequest;

    const dateTimeOfRequest = new Date(requestedAt);
    const dateTimeOptions = { dateStyle: 'long', timeStyle: 'short' };

    return (
      <Box>
        {pendingRequestTableRow(
          'Current Permission:',
          StringUtils.upperCaseFirstLetter(roleAtTimeOfRequest)
        )}
        {pendingRequestTableRow(
          'Requested Permission:',
          StringUtils.upperCaseFirstLetter(roleRequested)
        )}
        {pendingRequestTableRow(
          'Date/Time of Request:',
          dateTimeOfRequest.toLocaleString('en-GB', dateTimeOptions)
        )}
        {pendingRequestTableRow('Status:', 'Pending')}
      </Box>
    );
  };

  const confirmCancelModalButtonProps = [
    { label: 'Discard Request', outlined: true, onClick: onConfirmCancelRequest },
    { label: 'Back', onClick: closeConfirmCancelDialog }
  ];

  const confirmCancelDialog = () => (
    <Modal
      open={confirmCancelDialogOpen}
      onClose={closeConfirmCancelDialog}
      modalEntities={[
        {
          title: 'Discard request to change permission',
          content: (
            <Typography variant="body">
              {`Are you sure you want to discard your request for ${pendingRequest.attributes.role_requested} access?`}
            </Typography>
          ),
          buttonProps: confirmCancelModalButtonProps
        }
      ]}
    />
  );

  const modalContent = () => (
    <Box display="flex" flexDirection="column">
      {pendingRequest ? (
        <>
          {renderRequestReceivedText()}
          {renderPendingRequestTable()}
          {confirmCancelDialog()}
        </>
      ) : (
        <>
          {renderInstructionalText()}
          {renderPermissionsCards()}
        </>
      )}
    </Box>
  );

  const buttonProps = () => [
    {
      label: pendingRequest ? 'Discard Request' : 'Request Change',
      color: 'secondary',
      onClick: pendingRequest ? openConfirmCancelDialog : onConfirmNewRequest,
      disabled: (!pendingRequest && !selectedRole) || loading,
      loading
    }
  ];

  return (
    <Modal
      open
      onClose={closeModal}
      modalEntities={[
        {
          title: 'Change Permission',
          content: modalContent(),
          buttonProps: buttonProps(),
          loading
        }
      ]}
    />
  );
}
