/* eslint-disable class-methods-use-this */
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class FeelGoodFridayAPI {
  getFeelGoodFridayData = () =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/feel_good_friday.json`
    });
}

export default new FeelGoodFridayAPI();
