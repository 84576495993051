/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { SpotlightBanner, Table, Infographic } from '@rocksteady-music-school/rms-ui';
import MatchFundingForm from '../MatchFundingForm/MatchFundingForm';
import bursarySpotlight1 from '../../assets/images/bursary_spotlight_1.png';
import bursarySpotlight2 from '../../assets/images/bursary_spotlight_2.png';
import handMail from '../../assets/images/hand_mail.svg';
import pen from '../../assets/images/pen.svg';
import scroll from '../../assets/images/scroll.svg';
import MatchFundingModal from '../MatchFundingModal/MatchFundingModal';
import MatchFundingContentPricingTableData from './MatchFundingContentPricingTableData';

const infographicTitle = 'more information about the match-funding scheme';

const infographicItems = [
  {
    title: 'Enrol a child for a full academic year',
    description:
      'Children on the Match-Funding Scheme have the same experience as parent funded children. To get the most out of the programme it’s good to keep a child on the programme for a minimum of a term, but keeping them on for longer will allow them to build their musical skill, gain confidence through multiple performances and take part in accreditation. However, the programme is flexible so you can subscribe and unsubscribe at any point.',
    icon: pen
  },
  {
    title: "Provide us with their parent or carer's email",
    description:
      'If you choose to provide the parent or carer’s email, they can keep in touch with their child’s learning and hear about upcoming concerts through the parent timeline which in turn can help build their relationship with the school.',
    icon: handMail
  },
  {
    title: 'Invoiced on a termly basis',
    description: 'Invoices are sent on a termly basis with payment due on receipt.',
    icon: scroll
  }
];

export default function MatchFundingContent({ placesInfo, description, loading, timetableRate }) {
  const [formVisible, setFormVisible] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const timetablePrice = useMemo(() => {
    return timetableRate && parseFloat(timetableRate / 100).toFixed(2);
  }, [timetableRate]);

  const matchPricingTableData =
    timetablePrice && MatchFundingContentPricingTableData(timetablePrice);

  const { numOfKids } = placesInfo;
  useEffect(() => {
    if (formVisible) {
      document.getElementById('bursary-sf-form').scrollIntoView({ behavior: 'smooth' });
    }
  }, [formVisible]);

  const spotlightImage = () => (numOfKids > 0 ? bursarySpotlight2 : bursarySpotlight1);

  const formIntro = () => (
    <>
      <Typography variant="body" component="p" marginY={16}>
        To register a child in the Match-Funding scheme, fill in their details below and the Schools
        team will make their best effort to enrol them in a age-appropriate band. You will be
        invoiced in line with the Match-Funding Scheme based on the total number of children
        enrolled i.e. if you have 4 children enrolled, you will only pay for 2.
      </Typography>

      <Alert severity="warning">
        Any requests made after your lesson week commencing the 17th of June will be enrolled for
        September.
      </Alert>

      <Typography variant="body" component="p" marginY={16}>
        <Typography variant="body" color="rubyTuesday.110">
          *
        </Typography>{' '}
        Mandatory field
      </Typography>
    </>
  );

  const title = () => (
    <Typography variant="h1" mt={{ xs: 0, md: 24 }} mb={24}>
      Match-Funding Scheme
    </Typography>
  );

  const openTheForm = () => {
    setFormVisible(true);
    // If the form was already open on the page, we need to scroll it into view when the modal closes.
    // Somehow this doesn't work when you do it from the modal unless it has a bit of a timeout,
    // which is why we're waiting 100ms below before doing it
    setTimeout(() => {
      document.getElementById('bursary-sf-form').scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleFirstBtnClick = () => openTheForm();
  const handleOpeningStatusModal = () => setStatusModalOpen(true);
  const handleModalClose = () => setStatusModalOpen(false);

  const pageContent = () => (
    <>
      {title()}
      <SpotlightBanner
        title={placesInfo.title}
        titleColor={placesInfo.color}
        description={placesInfo.description}
        image={spotlightImage()}
        btnTextOne="Register a Pupil"
        btnTextTwo="Check Enrolled Pupils"
        onBtnClickOne={handleFirstBtnClick}
        onBtnClickTwo={handleOpeningStatusModal}
        loading={loading}
      />
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Box marginTop={24} marginBottom={24}>
            {description}
          </Box>
        </Grid>
      </Grid>
      <Box mb={24}>
        <Infographic
          title={infographicTitle}
          items={infographicItems}
          bgColor="#E5FDF7"
          prominentFirstItem
        />
      </Box>

      <Box
        id="bursary-sf-form"
        padding={40}
        mb={24}
        borderRadius="10px"
        sx={{ display: formVisible ? 'block' : 'none', border: '2px solid #D2F0FB' }}>
        <Typography variant="h2">REGISTER PUPILS - school funded and Bursary spaces</Typography>
        <Grid container>
          <Grid item xs={12} lg={9.5}>
            {formIntro()}
          </Grid>
        </Grid>
        <MatchFundingForm />
      </Box>

      {statusModalOpen && (
        <MatchFundingModal
          open={statusModalOpen}
          onClose={handleModalClose}
          placesInfo={placesInfo}
          setFormVisible={openTheForm}
        />
      )}

      {matchPricingTableData && timetablePrice && (
        <Table {...matchPricingTableData} maxWidth={920} />
      )}

      {matchPricingTableData && timetablePrice && (
        <Typography variant="body" component="p" marginY={16}>
          *prices excl VAT
        </Typography>
      )}
    </>
  );

  return pageContent();
}
