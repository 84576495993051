import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import { useUserData } from '../../contexts/UserContext';

export default function SchoolYearDropdown({ handleYearChange, selectedYear, valueMissing }) {
  const { currentSchoolYear } = useUserData();

  // Using values as strings here because the MUI Select element has a bug
  // which doesn't allow menu items with the value of 0 as it considers it falsey as if no value is selected
  const yearValues = [
    {
      key: 'key-year-pre',
      value: '-1',
      text: `Starts Reception in September ${currentSchoolYear + 1}`
    },
    { key: 'key-year-r', value: '0', text: 'Reception' },
    { key: 'key-year-1', value: '1', text: 'Year 1' },
    { key: 'key-year-2', value: '2', text: 'Year 2' },
    { key: 'key-year-3', value: '3', text: 'Year 3' },
    { key: 'key-year-4', value: '4', text: 'Year 4' },
    { key: 'key-year-5', value: '5', text: 'Year 5' },
    { key: 'key-year-6', value: '6', text: 'Year 6' },
    { key: 'key-year-7', value: '7', text: 'Year 7' },
    { key: 'key-year-8', value: '8', text: 'Year 8' },
    { key: 'key-year-9', value: '9', text: 'Year 9' },
    { key: 'key-year-10', value: '10', text: 'Year 10' },
    { key: 'key-year-11', value: '11', text: 'Year 11' },
    { key: 'key-year-12', value: '12', text: 'Year 12' },
    { key: 'key-year-13', value: '13', text: 'Year 13' }
  ];

  const label = 'Pupil’s Current School Year';

  return (
    <TextField
      required
      fullWidth
      id="select-year"
      name="yearGroup"
      select
      label={label}
      value={selectedYear || ''}
      onChange={handleYearChange}
      error={valueMissing}
      helperText={valueMissing ? 'Please select the pupil’s year group' : ''}
      sx={{
        margin: 0
      }}>
      {yearValues.map((year) => (
        <MenuItem key={year.key} value={year.value}>
          {year.text}
        </MenuItem>
      ))}
    </TextField>
  );
}

SchoolYearDropdown.propTypes = {
  handleYearChange: PropTypes.func.isRequired,
  valueMissing: PropTypes.bool.isRequired,
  selectedYear: PropTypes.string
};

SchoolYearDropdown.defaultProps = {
  selectedYear: null
};
