const userRoles = Object.freeze({
  ADMIN: {
    roleName: 'admin',
    roleAbilities: [
      'Access to Feel Good Friday',
      'Download learning resources and documents',
      'View timetables, Rocksteady contacts',
      'Download PDF timetables',
      'Use Bursary and School Funded places',
      'Invite colleagues to create their accounts'
    ]
  },
  STAFF: {
    roleName: 'staff',
    roleAbilities: [
      'Access to Feel Good Friday',
      'Download learning resources and documents',
      'View timetables, Rocksteady contacts',
      'Download PDF timetables',
      'Invite colleagues to create their accounts'
    ]
  },
  BASIC: {
    roleName: 'basic',
    roleAbilities: [
      'Access to Feel Good Friday',
      'Download learning resources and documents',
      'View Rocksteady contacts',
      'Invite colleagues to create their accounts'
    ]
  }
});

export default userRoles;
