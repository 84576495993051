import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let startParams;

if (process.env.NODE_ENV === 'test') {
  startParams = {};
} else {
  startParams = {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()]
  };
}

export default Bugsnag.start(startParams);
