/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Typography } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

export default function BackToPathLink({ onNavigatingBack, text }) {
  return (
    <Typography variant="bodyLink" component="p" onClick={onNavigatingBack} sx={styles.backLink}>
      <ArrowCircleLeftOutlinedIcon sx={styles.icon} className="link-icon" />
      <Typography variant="bodyLink" sx={{ textDecorationColor: 'white' }}>
        {text}
      </Typography>
    </Typography>
  );
}

const styles = {
  backLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    cursor: 'pointer',
    '&:hover': {
      '& .link-icon': {
        marginLeft: -9,
        marginRight: 19
      }
    }
  },
  icon: {
    marginRight: 10,
    transition: '0.3s'
  }
};
