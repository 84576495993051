import { Link } from 'react-router-dom';
import paths from '../../consts/paths';
import ClippedImgCardContainer from './ClippedImgCardContainer';
import LinkToPage from './LinkToPage';
import fgfBg from '../../assets/images/fgf.png';

export default function FgfCard() {
  const feelGoodFriday = () => (
    <Link style={{ display: 'contents' }} to={paths.FEEL_GOOD_FRIDAY}>
      <ClippedImgCardContainer
        shadowLeft
        bgImg={fgfBg}
        clipPath="polygon(65% 0, 100% 0, 100% 100%, 81% 100%)"
        floatImgRight
        bgcolor="greenOnions.60">
        <LinkToPage text="Feel Good Friday" showIcon />
      </ClippedImgCardContainer>
    </Link>
  );

  return feelGoodFriday();
}
