/* eslint-disable no-use-before-define */
import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { useEffect } from 'react';
import { InstrumentCheckbox } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';

const InstrumentPreferences = ({
  formValues,
  setFormValues,
  handleInputChange,
  wouldDos,
  setWouldDos
}) => {
  // Effect so that if the user changes the first choice to be the same as the current second choice,
  // the second choice is reset to "no preference"
  useEffect(() => {
    if (formValues.firstChoice === formValues.secondChoice) {
      setFormValues({ ...formValues, secondChoice: 'no_preference' });
    }
  }, [formValues.firstChoice]);

  const mappedChoices = (choices) =>
    choices.map((choice) => ({ text: StringUtils.humanize(choice), value: choice }));

  const instrumentChoicesOptions = (field) => {
    let availableOptions = [...wouldDos];

    // For "second choice" don't show the selected "first choice" option
    if (field === 'secondChoice') {
      availableOptions = availableOptions.filter((opt) => opt !== formValues.firstChoice);
    }

    // Map all the instruments as MenuItems
    const instruments = mappedChoices(availableOptions).map((instr) => (
      <MenuItem key={instr.value} value={instr.value}>
        {instr.text}
      </MenuItem>
    ));

    // Add to the mapped menu instruments the "no preference" option
    instruments.push(
      <MenuItem key="no_preference" value="no_preference">
        No Preference
      </MenuItem>
    );

    return instruments;
  };

  const displayFirstChoice = wouldDos.length > 1;
  const displaySecondChoice = wouldDos.length > 2 && formValues.firstChoice !== 'no_preference';

  const displayFirstAndSecondChoices = (field) => {
    if (field === 'firstChoice') {
      if (displayFirstChoice) return true;
      return false;
    }
    if (field === 'secondChoice') {
      if (displaySecondChoice) return true;
      return false;
    }

    return false;
  };

  const firstChoiceHelperText = (name) =>
    name === 'firstChoice' ? (
      <Typography variant="bodySecondary">
        If your first choice isn’t currently available, we’ll keep you on the waiting list to change
        instruments later.
      </Typography>
    ) : (
      ''
    );

  const instrumentChoice = (name, label) => (
    <FormControl
      fullWidth
      sx={{
        display: displayFirstAndSecondChoices(name) ? '' : 'none',
        width: displaySecondChoice ? '100%' : '50%'
      }}>
      <InputLabel shrink id="instrument-choice-label">
        {label}
      </InputLabel>
      <Select
        labelId="instrument-choice-label"
        id={name}
        displayEmpty
        fullWidth
        name={name}
        input={<OutlinedInput notched label={label} />}
        value={formValues[name]}
        onChange={handleInputChange}
        sx={{ margin: 0 }}>
        {instrumentChoicesOptions(name)}
      </Select>
      <FormHelperText>{firstChoiceHelperText(name)}</FormHelperText>
    </FormControl>
  );

  const resetFirstAndSecondChoices = (instrument) => {
    // If the instrument checkbox is unchecked and it was one of the first or second choices
    // set the choice to be no_preference
    ['firstChoice', 'secondChoice'].forEach((choice) => {
      if (formValues[choice] === instrument) {
        setFormValues({ ...formValues, [choice]: 'no_preference' });
      }
    });

    // If the user unchecks enough instruments to end up hiding the first and second choice dropdowns
    // set their values to no_preference, just in case they had one of the remaining would dos set up
    if (!displayFirstChoice) setFormValues({ ...formValues, firstChoice: 'no_preference' });
    if (!displaySecondChoice) setFormValues({ ...formValues, secondChoice: 'no_preference' });
  };

  const updateInstrumPref = (instrument) => {
    // When unchecking the checkbox
    if (wouldDos.includes(instrument)) {
      const newWouldDos = [...wouldDos].filter((wd) => wd !== instrument);
      setWouldDos(newWouldDos);
      // When checking the checkbox
    } else {
      setWouldDos([...wouldDos, instrument]);
    }
  };

  const handleCheckboxToggle = (instrument) => {
    updateInstrumPref(instrument);
    resetFirstAndSecondChoices(instrument);
  };

  const wouldDoCheckbox = (instrument) => (
    <InstrumentCheckbox
      key={instrument}
      label={instrument}
      checked={wouldDos.includes(instrument)}
      onClick={() => handleCheckboxToggle(instrument)}
    />
  );

  const wouldDosCheckboxes = () => (
    <FormGroup row sx={{ gap: 16 }}>
      {['drums', 'guitar', 'keyboard', 'vocals'].map((instr) => wouldDoCheckbox(instr))}
    </FormGroup>
  );

  const firstAndSecondChoices = () => (
    <Stack
      mt={displayFirstChoice ? 19 : 0}
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
      spacing={{ xs: 12, lg: 40 }}
      width={{ xs: '100%', lg: '75%' }}>
      {instrumentChoice('firstChoice', 'First Choice')}
      {instrumentChoice('secondChoice', 'Second Choice')}
    </Stack>
  );

  const preferences = () => {
    return (
      <>
        <Typography variant="bodyBold" component="p" mb={16}>
          Instrument Preference
        </Typography>
        {wouldDosCheckboxes()}
        <Typography variant="bodySecondary" component="p" mt={8}>
          If there is no preference, any available will be assigned.
        </Typography>
        {firstAndSecondChoices()}
      </>
    );
  };

  return preferences();
};

export default InstrumentPreferences;
