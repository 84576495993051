/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Box, Grid, Link, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@rocksteady-music-school/rms-ui';
import gigglingKid from '../../assets/images/giggling_kid.png';
import paths from '../../consts/paths';
import contactDetails from '../../consts/contactDetails';

// Due to this component being used within the error boundary, we are unable to make use
// of the useAuth/Navigation hooks due to the nature of the boundary having to be wrapped
// outside of the routes/contexts.
// Therefore, the navigate function and isAuthenticated bool are passed in as props.

export default function ErrorPageContent({
  name = 'somethingWentWrong', // name of page for look up in CONTENT const defined below
  isAuthenticated = false, // bool to determine content to display depending on auth status
  navigate = null // function to navigate to specified page/route name
}) {
  const CONTENT = {
    somethingWentWrong: {
      title: 'SOMETHING WENT WRONG',
      textParagraphs: [
        <span>
          We keep track of these usually temporary errors, but feel free to&nbsp;
          <Link variant="bodyLink" href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
            contact us
          </Link>
          &nbsp;if refreshing the page in your browser doesn&apos;t fix things.
        </span>,
        <>Please be assured that normal service will resume.</>
      ]
    },
    pageNotFound: {
      title: 'PAGE NOT FOUND',
      buttonLinkText: isAuthenticated ? 'Return to Home' : 'Return to Sign In',
      navigateTo: () => navigate(isAuthenticated ? paths.ROOT : paths.LOG_IN),
      textParagraphs: [
        <>Oops! It seems we missed a beat!</>,
        <>But don&apos;t panic, the show must go on...</>,
        <span>
          If you are looking for something specific that you can&apos;t find, please&nbsp;
          <Link variant="bodyLink" href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
            contact us
          </Link>
          .
        </span>
      ]
    }
  };

  const relevantContent = CONTENT[name] || CONTENT.somethingWentWrong;

  const renderTitleSection = () => (
    <Typography mb={32} variant="h1" sx={styles.text}>
      {relevantContent.title}
    </Typography>
  );

  const renderTextParagraphSections = () =>
    relevantContent.textParagraphs.map((text) => (
      <Typography key={uuidv4()} mb={24} variant="body" sx={styles.text}>
        {text}
      </Typography>
    ));

  const renderButtonLinkSection = () => (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      mb={32}>
      <Button
        color="secondary"
        label={relevantContent.buttonLinkText}
        onClick={relevantContent.navigateTo}
      />
    </Box>
  );

  return (
    <Box className="page-container" px={{ xs: 16, md: 40 }} py={{ xs: 32, md: 40 }}>
      <Grid container spacing={24} display="flex" justifyContent="center">
        <Grid item xs={12} md={6}>
          {renderTitleSection()}
          {renderTextParagraphSections()}
          {!!relevantContent.buttonLinkText && renderButtonLinkSection()}
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={gigglingKid} style={styles.image} alt="giggling kid" />
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = {
  text: {
    whiteSpace: 'pre-line',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center'
  },
  image: {
    width: '100%',
    height: '100%'
  }
};
