/* eslint-disable class-methods-use-this */

import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class SchoolSubmittedKidDatumAPI {
  postFormData = (params) =>
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/school_submitted_kid_data`,
      config: { data: params }
    });
}

export default new SchoolSubmittedKidDatumAPI();
