/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import paths from '../../consts/paths';
import { useUserData } from '../../contexts/UserContext';
import LinkToPage from './LinkToPage';

function DoubleCardContainer({ children }) {
  return (
    <Box
      gridColumn={{ xs: 'span 2', sm: 'span 1' }}
      gridRow="span 2"
      bgcolor="white"
      px={14}
      borderRadius="10px"
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="space-evenly">
      {children}
    </Box>
  );
}

export default function FundingCards({ fundingData, loading }) {
  const { isAdmin } = useUserData();

  const { schoolFundedPlaces, bursaryPlaces } = fundingData || {};

  const bursaryMessage = () => {
    if (bursaryPlaces > 0) return 'You are using your bursary place';
    if (bursaryPlaces === 0 && isAdmin) return 'Use bursary place';
    if (bursaryPlaces === 0) return 'Contact us to book';
    return '';
  };

  const fundedMessage = () => {
    if (schoolFundedPlaces > 0) return `${schoolFundedPlaces} funded children`;
    if (schoolFundedPlaces === 0 && isAdmin) return 'Add school funded places';
    if (schoolFundedPlaces === 0) return 'Contact us to book';
    return '';
  };

  const infoText = (text, color) =>
    loading ? (
      <Skeleton width="100%" />
    ) : (
      <Typography variant="bodyBold" sx={{ whiteSpace: 'pre-line', color }}>
        {text}
      </Typography>
    );

  const fundingCard = (content, path) => {
    if (isAdmin) {
      return (
        <Link style={{ display: 'contents' }} to={path}>
          <Box>{content}</Box>
        </Link>
      );
    }

    return <Box>{content}</Box>;
  };

  const doubleCard = () => (
    <DoubleCardContainer>
      {fundingCard(
        <>
          <LinkToPage text="Bursary Places" showIcon={isAdmin} />
          {infoText(bursaryMessage(), bursaryPlaces ? 'greenOnions.150' : 'rubyTuesday.110')}
        </>,
        paths.MATCH_FUNDING
      )}

      <Divider variant="middle" sx={styles.divider} />

      {fundingCard(
        <>
          <LinkToPage text="School Funded" showIcon={isAdmin} />
          {infoText(fundedMessage(), schoolFundedPlaces ? 'fadeToGrey.main' : 'rubyTuesday.110')}
        </>,
        paths.MATCH_FUNDING
      )}
    </DoubleCardContainer>
  );

  return doubleCard();
}

const styles = {
  divider: { borderColor: '#D2F0FB', width: '100%' }
};
