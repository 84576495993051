/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Container, Grid, Box, CircularProgress, Toolbar, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Menu, Footer, MobileAppBar } from '@rocksteady-music-school/rms-ui';
import UserAPI from '../api/UserAPI';
import logo from '../assets/images/rs-logo-xs-white.svg';
import menuItems from '../consts/menuItems';
import paths from '../consts/paths';
import {
  containerMarginX,
  logoHeight,
  xsContainerMarginX,
  drawerWidth
} from '../consts/styleValues';
import { useAuth } from '../contexts/AuthContext';
import WindowUtils from '../utils/WindowUtils';
import { useUserData } from '../contexts/UserContext';
import CookieConsentModal from '../components/CookieConsentModal/CookieConsentModal';
import StringUtils from '../utils/StringUtils';
import LogoModal from '../components/LogoModal/LogoModal';

// eslint-disable-next-line no-unused-vars
export default function Layout() {
  const navigate = useNavigate();
  const { signOut, isAuthenticated } = useAuth();
  const { setUserData, schoolLogo, schoolName, role, isAdmin, isActiveSchool, activeSince } =
    useUserData();

  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const schoolLogoUrl = schoolLogo ? StringUtils.pathToAbbeyRoadUrl(schoolLogo) : null;

  const currentPath = WindowUtils.currentPath();

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);

      UserAPI.fetch()
        .then((res) => {
          setUserData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          // If it's not a 401, throw the error so that we get the general error message on screen
          // we don't want the user to have access to any part of the site if we don't know who the user is
          if (err.request.status !== 401) {
            setUserData(() => {
              throw new Error(err);
            });
          }
        });
    } else {
      setUserData(null);
    }
  }, [isAuthenticated]);

  const handleDrawerToggle = () => {
    setMenuMobileOpen(!menuMobileOpen);
  };

  const appBarMobile = () => (
    <MobileAppBar isAuthenticated={isAuthenticated} handleDrawerToggle={handleDrawerToggle} />
  );

  const logoDisplayRules = () => {
    if (!isMobile && !isAuthenticated) {
      return 'flex';
    }
    return { xs: 'none', lg: 'flex' };
  };

  const logoContainer = () => (
    <Grid container display={logoDisplayRules()}>
      <Grid item xs={2} pb={logoHeight}>
        <Link to={paths.ROOT}>
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            style={isAuthenticated ? styles.logo : { ...styles.logo, position: 'absolute' }}
          />
        </Link>
      </Grid>
    </Grid>
  );

  const menuContainer = () => (
    <Menu
      items={menuItems}
      currentPath={currentPath}
      menuMobileOpen={menuMobileOpen}
      handleDrawerToggle={handleDrawerToggle}
      onSignOut={() => {
        signOut(() => navigate('/login'));
      }}
      setLogoModalOpen={() => setLogoModalOpen(true)}
      schoolLogo={schoolLogoUrl}
      schoolName={schoolName}
      role={role}
      isAdmin={isAdmin}
      isActiveSchool={isActiveSchool}
      activeSince={activeSince}
    />
  );

  const pageWithMenu = () => (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          width: { lg: drawerWidth },
          flexShrink: { lg: 0 }
        }}
        aria-label="menu items">
        {menuContainer()}
      </Box>
      <Box
        component="main"
        sx={{ p: 0, width: { xs: '100%', lg: `calc(100% - ${drawerWidth}px)` } }}>
        <Outlet />
        <Footer isAuthenticated={isAuthenticated} />
      </Box>
    </Box>
  );

  const loadingIndicator = () => (
    <Box display="flex" justifyContent="center">
      <CircularProgress sx={{ color: 'white' }} />
    </Box>
  );

  const mainContent = () => {
    if (loading) return loadingIndicator();

    if (isAuthenticated) return pageWithMenu();

    return <Outlet />;
  };

  return (
    <Container sx={{ ...styles.mainContainer, px: { xs: 0 } }}>
      <Box
        sx={{ ...styles.contentContainer }}
        mx={{ xs: xsContainerMarginX, lg: containerMarginX }}>
        {logoContainer()}
        {/* For pre-login screens, only show it if the user is on a phone */}
        {!isMobile && !isAuthenticated ? null : appBarMobile()}
        <Toolbar sx={{ height: logoHeight, position: 'fixed' }} />

        <Box flexGrow={1}>{mainContent()}</Box>

        <CookieConsentModal />

        {/* When authenticated the footer needs to be included in a special layout within pagewithMenu() */}
        {!isAuthenticated && <Footer isAuthenticated={isAuthenticated} />}
        {logoModalOpen && <LogoModal setLogoModalOpen={setLogoModalOpen} />}
      </Box>
    </Container>
  );
}

const styles = {
  mainContainer: {
    minHeight: '100vh',
    minWidth: '100vw',
    margin: 0,
    padding: 0
  },
  contentContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  logo: {
    marginLeft: -5,
    position: 'fixed',
    top: 0,
    left: 0
  }
};
