/* eslint-disable react/prop-types */
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import FileUtils from '../../utils/FileUtils';

const MAX_IMG_SIZE_MB = 5;

export default function ImagePicker({ setImgData, setAttachment, setUploadErrorMsg }) {
  const theme = useTheme();

  const attachAndUpdateLogoPreview = (reader, file) => {
    setAttachment(file);

    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
  };

  const handleInputChange = async (event) => {
    setUploadErrorMsg(null);

    const files = Array.from(event.target.files);
    const [file] = files;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const dimensions = await FileUtils.imageDimensions(reader);
    const invalidDimensions = dimensions.width < 300 || dimensions.height < 300;

    if (!FileUtils.fileSizeIsValid(file, MAX_IMG_SIZE_MB)) {
      setUploadErrorMsg('The image exceeds 5MB. Please upload an image with a smaller size.');
    } else if (invalidDimensions) {
      setUploadErrorMsg('The image should be at least 300x300px.');
    } else {
      attachAndUpdateLogoPreview(reader, file);
    }
  };

  const imagePickerInput = () => (
    <input
      accept=".jpg,.jpeg,.png,.gif"
      id="logo-picker"
      type="file"
      onChange={handleInputChange}
      style={{ display: 'none' }}
    />
  );

  const uploadIcon = () => (
    <FontAwesomeIcon
      icon={faCloudUploadAlt}
      style={{ marginRight: 8, fontSize: 20 }}
      color={theme.palette.primary.main}
    />
  );

  const imagePickerLabel = () => (
    <Typography
      variant="bodyLink"
      component="p"
      mb={8}
      display="flex"
      alignItems="center"
      sx={{ textDecoration: 'none' }}>
      {uploadIcon()}
      <Typography variant="bodyLink" component="span">
        Upload your school logo
      </Typography>
    </Typography>
  );

  const imagePicker = () => (
    <label htmlFor="logo-picker">
      {imagePickerInput()}
      {imagePickerLabel()}
    </label>
  );

  return imagePicker();
}
