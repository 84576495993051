import { Link } from 'react-router-dom';
import paths from '../../consts/paths';
import ClippedImgCardContainer from './ClippedImgCardContainer';
import LinkToPage from './LinkToPage';
import contactsBg from '../../assets/images/contacts.png';

export default function ContactsCard() {
  const contacts = () => (
    <Link style={{ display: 'contents' }} to={paths.CONTACTS}>
      <ClippedImgCardContainer
        shadowLeft
        floatImgRight
        bgImg={contactsBg}
        clipPath="polygon(50% 0, 100% 0, 100% 100%, 65% 100%)"
        bgcolor="secondary.main">
        <LinkToPage text="Contacts" showIcon />
      </ClippedImgCardContainer>
    </Link>
  );

  return contacts();
}
