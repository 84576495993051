/* eslint-disable class-methods-use-this */
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class CallbackAPI {
  requestCallback = (config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/request_callback.json`,
      config
    });
}

export default new CallbackAPI();
