/* eslint-disable react/prop-types */
/* eslint-disable default-case */

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ImpactDashboard } from '@rocksteady-music-school/rms-ui';
import paths from '../../consts/paths';
import { useUserData } from '../../contexts/UserContext';
import SchoolAPI from '../../api/SchoolAPI';

export default function ImpactDashboardContainer({ setFetchError }) {
  const { schoolName, schoolUrn } = useUserData();
  const [dataLoading, setDataLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [dataRangeKey, setDataRangeKey] = useState('current_academic_year');

  useEffect(() => {
    if (schoolUrn) {
      setDataLoading(true);
      SchoolAPI.getImpactDashboardData(schoolUrn)
        .then((res) => {
          setDashboardData(res.data.dashboard);
          setDataLoading(false);
        })
        .catch(() => setFetchError(true));
    }
  }, []);

  const title = () => (
    <Typography variant="h2" color="primary.main">
      Dashboard
    </Typography>
  );

  // eslint-disable-next-line consistent-return
  const keyFromDropdown = (value) => {
    switch (value) {
      case 'This academic year':
        return 'current_academic_year';

      case 'Data since 2021':
        return 'since_september_2021';
    }
  };

  const handleFilter = (event) => {
    setDataRangeKey(keyFromDropdown(event?.target?.value));
  };

  const filterOptions = () => ['This academic year', 'Data since 2021'];

  const dropdown = () => (
    <FormControl sx={{ width: { xs: '100%', sm: '180px' }, marginTop: { xs: 34, sm: 0 } }}>
      <InputLabel id="impact-dashboard-filter">Filter</InputLabel>
      <Select
        labelId="impact-dashboard-filter"
        id="impact-filter"
        defaultValue={filterOptions()[0]}
        label="Filter"
        onChange={handleFilter}>
        {filterOptions().map((item) => (
          <MenuItem key={uuidv4()} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const stat = (metric) => dashboardData?.[dataRangeKey][metric];

  // eslint-disable-next-line consistent-return
  const tooltipFromKey = () => {
    switch (dataRangeKey) {
      case 'current_academic_year':
        return `The number of ${schoolName} pupils currently taking Rocksteady lessons`;

      case 'since_september_2021':
        return 'The amount of kids who have ever taken Rocksteady lessons';
    }
  };

  const fgfLink = () => (
    <Typography variant="body" color="white">
      Feel Good Friday lessons are available to your whole school for free,{' '}
      <Link to={paths.FEEL_GOOD_FRIDAY} style={{ color: 'white', textDecoration: 'underline' }}>
        click here for more details
      </Link>
    </Typography>
  );

  const items = () => [
    {
      type: 'pupils',
      number: stat('pupils_enrolled'),
      tooltipText: tooltipFromKey(),
      comingSoon: stat('pupils_enrolled') === 0
    },
    {
      type: 'performances',
      number: stat('performances'),
      tooltipText:
        'Performances are an opportunity for the whole school to experience live music and they contribute to building confidence and wellbeing',
      comingSoon: stat('performances') === 0
    },
    {
      type: 'lessons',
      number: stat('lessons'),
      tooltipText:
        'Total number of individual pupil lessons (e.g. a band rehearsal including 5 pupils equates to 5 individual pupil lessons)',
      comingSoon: stat('lessons') === 0
    },
    {
      type: 'streams',
      number: stat('whole_class_lessons'),
      tooltipText: fgfLink(),
      comingSoon: stat('whole_class_lessons') < 3
    },
    {
      type: 'skills',
      number: stat('skills_achieved'),
      tooltipText:
        "Individually completed skills from Rocksteady's curriculum covering instrument skills, band skills and performance",
      comingSoon: stat('skills_achieved') === 0
    }
  ];

  // memoize the dashboard so that the component does not re-render while the animation is in progress
  const memoizedDashboard = useMemo(() => {
    return <ImpactDashboard items={items()} />;
  }, [dashboardData, dataRangeKey]);

  const content = () => {
    return (
      <Box>
        <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap" mb={24}>
          {title()}
          {dropdown()}
        </Box>
        {dataLoading ? <Skeleton height={200} style={{ transform: 'none' }} /> : memoizedDashboard}
      </Box>
    );
  };

  return content();
}
