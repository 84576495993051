/* eslint-disable react/prop-types */
import { Avatar, Box, Typography } from '@mui/material';
import schoolLogoPlaceholder from '../../assets/images/school_logo_placeholder.png';

export default function LogoPreview({ imgData, setUploadErrorMsg, setAttachment, setImgData }) {
  const handleRemovingImage = () => {
    setUploadErrorMsg(null);
    setImgData(null);
    setAttachment(null);
  };

  const logoPreview = () => (
    <Box
      width="fit-content"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <Avatar
        alt="school logo"
        src={imgData || schoolLogoPlaceholder}
        className="sp-avatar"
        sx={{ width: 200, height: 200 }}
      />
      <Typography
        variant="bodyLink"
        display={imgData ? 'block' : 'none'}
        component="p"
        mt={8}
        onClick={handleRemovingImage}>
        Remove image
      </Typography>
    </Box>
  );

  return logoPreview();
}
