export const INSTRUMENT_SLOTS = Object.freeze([
  'drums_1',
  'drums_2',
  'bass_1',
  'guitar_1',
  'guitar_2',
  'keyboard_1',
  'keyboard_2',
  'vocals_1',
  'vocals_2'
]);

export const EXCEPTION_SLOTS = Object.freeze([
  'drums_3',
  'bass_2',
  'guitar_3',
  'keyboard_3',
  'vocals_3'
]);

export const INSTRUMENTS = ['drums', 'bass', 'guitar', 'keyboard', 'vocals'];
