import userRoles from '../consts/userRoles';

export default class UserUtils {
  static isAdmin = (role) => role === userRoles.ADMIN.roleName;

  static isStaff = (role) => role === userRoles.STAFF.roleName;

  static isBasic = (role) => role === userRoles.BASIC.roleName;

  static isAdminOrStaff = (role) =>
    [userRoles.ADMIN.roleName, userRoles.STAFF.roleName].includes(role);
}
