import { useEffect, useState } from 'react';
import _ from 'lodash';
// Hook
function useWindowSize(msDelay = 0) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // If msDelay is 1000 for example, then call handleResize once a second
    const debouncedHandleResize = _.debounce(handleResize, msDelay);
    // Add event listener
    window.addEventListener('resize', debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;
