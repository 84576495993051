/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ContentMissingPlaceholder } from '@rocksteady-music-school/rms-ui';
import useWindowSize from '../../hooks/useWindowSize';
import StringUtils from '../../utils/StringUtils';
import BandSlot from './BandSlot';
import { INSTRUMENTS, INSTRUMENT_SLOTS, EXCEPTION_SLOTS } from '../../consts/slots';
import InstrumentIcon from '../InstrumentIcon/InstrumentIcon';

function BandsTable({ bandsData }) {
  const { bands, allKids } = bandsData;

  const bandLetters = Object.keys(bands || {});
  const ttHasBands = bandLetters.length;

  const theme = useTheme();
  const windowSize = useWindowSize(1000);
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const targetRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setTableWidth(targetRef.current.offsetWidth);
    }
  }, [windowSize]);

  const instrument = (slot) => slot.substring(0, slot.indexOf('_'));

  const showException = (slot) => Object.keys(bands).find((ob) => bands[ob].slots[slot].id !== '');

  const slotExceptionActive = (slot) => (showException(slot) ? 1 : 0);

  const numberOfExceptions = () => {
    let num = 0;
    EXCEPTION_SLOTS.forEach((slot) => {
      num += slotExceptionActive(slot);
    });

    return num;
  };

  const anyExceptionsPresent = () => {
    const exceptions = EXCEPTION_SLOTS.map((slot) => showException(slot));
    return !!_.compact(exceptions).length;
  };

  const colSplit = () => {
    // Display 2.5 columns if it's a small device (horizontally scrollable)
    if (isSmall) return 3.5;

    if (isExtraLarge) {
      // If it's an extra large device display 14 columns (which is all of them normally)
      // or 14.5 columns if we have exceptions, which will make the table horizontally scrollable
      return anyExceptionsPresent() ? 14.5 : 14;
    }

    // On any large but not xl screens, display 11.5 columns (horizontally scrollable)
    return 11.5;
  };

  // If there are no bands, show all 14 columns, otherwise calculate how many columns to show
  const colWidth = ttHasBands ? tableWidth / colSplit() : 14;

  const tableHeadDetailCell = (text) => (
    <TableCell key={uuidv4()} sx={{ padding: 0 }} align="center">
      <Typography variant="bodyBold">{text}</Typography>
    </TableCell>
  );

  const tableHeadInstrumentCell = (slot, isException) => {
    if (isException && !showException(slot)) return null;

    return (
      <TableCell
        key={uuidv4()}
        sx={{ ...styles[`${instrument(slot)}-even`], padding: 6 }}
        align="center">
        {isException ? (
          <Typography variant="bodyBold">
            {`${StringUtils.upperCaseFirstLetter(instrument(slot))} (exception)`}
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <InstrumentIcon instrument={instrument(slot)} fill={theme.palette.paintItBlack.main} />
          </Box>
        )}
      </TableCell>
    );
  };

  const tableInstrumentCell = (slot, slotData, isException, index) => {
    if (isException && !showException(slot)) return null;

    const className = `${instrument(slot)}-${index % 2 ? 'even' : 'odd'}`;
    return tableRowCell(<BandSlot slot={slotData} key={uuidv4()} allKids={allKids} />, className);
  };

  const tableRowCell = (text, classname) => (
    <TableCell key={uuidv4()} sx={{ ...styles[classname], px: 8, py: 4 }} align="center">
      <Typography variant="body">{text}</Typography>
    </TableCell>
  );

  const tableHead = () => (
    <TableHead
      sx={{
        display: ttHasBands ? 'table-header-group' : { xs: 'none', sm: 'table-header-group' }
      }}>
      <TableRow>
        {/* Band details */}
        {tableHeadDetailCell('Bands')}
        {tableHeadDetailCell('Years')}
        {tableHeadDetailCell('Name')}
        {tableHeadDetailCell('Song')}
        {/* Instruments */}
        {INSTRUMENT_SLOTS.map((slot) => tableHeadInstrumentCell(slot, false))}
        {/* Exceptions */}
        {EXCEPTION_SLOTS.map((slot) => tableHeadInstrumentCell(slot, true))}
        {/* Notes */}
        {tableHeadDetailCell('Notes')}
      </TableRow>
    </TableHead>
  );

  const tableRow = (band, index) => {
    return (
      <TableRow key={uuidv4()}>
        {/* Band Details */}
        {tableRowCell(band.band_letter)}
        {tableRowCell(`${band.year_from} - ${band.year_to}`)}
        {tableRowCell(band.name)}
        {tableRowCell(band.song)}
        {/* Instruments */}
        {INSTRUMENT_SLOTS.map((slot) => tableInstrumentCell(slot, band.slots[slot], false, index))}
        {/* Exceptions */}
        {EXCEPTION_SLOTS.map((slot) => tableInstrumentCell(slot, band.slots[slot], true, index))}
        {/* Notes */}
        {tableRowCell(band.notes ? band.notes : '-')}
      </TableRow>
    );
  };

  const tableRows = () => {
    if (ttHasBands) {
      return <TableBody>{bandLetters.map((key, index) => tableRow(bands[key], index))}</TableBody>;
    }
    return (
      <TableBody>
        <TableRow sx={{ height: { xs: '100%', sm: 'auto' } }}>
          <TableCell colSpan={14} sx={{ padding: 0, height: 'fit-content' }}>
            <ContentMissingPlaceholder
              icons={INSTRUMENTS.map((instr) => (
                <InstrumentIcon
                  key={instr}
                  instrument={instr}
                  fill={theme.palette.paintItBlack[40]}
                />
              ))}
              text="No enrolled children"
            />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const colGroup = () => (
    <colgroup>
      {/* Band Details */}
      <col style={{ width: colWidth }} />
      <col style={{ width: colWidth }} />
      <col style={{ width: colWidth }} />
      <col style={{ width: colWidth }} />
      {/* Instruments */}
      {INSTRUMENT_SLOTS.map(() => (
        <col style={{ width: colWidth }} key={uuidv4()} />
      ))}
      {/* Exceptions */}
      {[...Array(numberOfExceptions()).keys()].map(() => (
        <col style={{ width: colWidth }} key={uuidv4()} />
      ))}
      {/* Notes */}
      <col style={{ width: colWidth }} />
    </colgroup>
  );

  const styles = {
    tableContainer: { height: '100%', width: '100%' },
    table: {
      width: '100%',
      height: '100%',
      tableLayout: 'fixed'
    },
    'drums-odd': { bgcolor: theme.palette.blueSuedeShoes[10] },
    'drums-even': { bgcolor: theme.palette.blueSuedeShoes[20] },
    'bass-odd': { bgcolor: theme.palette.prettyInPink[10] },
    'bass-even': { bgcolor: theme.palette.prettyInPink[20] },
    'guitar-odd': { bgcolor: theme.palette.rubyTuesday[10] },
    'guitar-even': { bgcolor: theme.palette.rubyTuesday[20] },
    'keyboard-odd': { bgcolor: theme.palette.yellowSubmarine[10] },
    'keyboard-even': { bgcolor: theme.palette.yellowSubmarine[20] },
    'vocals-odd': { bgcolor: theme.palette.greenOnions[10] },
    'vocals-even': { bgcolor: theme.palette.greenOnions[20] }
  };

  const overflowSettingForXlScreens = () => {
    if (ttHasBands) {
      return anyExceptionsPresent() ? 'auto' : 'hidden';
    }

    return 'hidden';
  };

  return (
    <TableContainer
      ref={targetRef}
      component={Box}
      sx={{
        ...styles.tableContainer,
        overflowX: {
          xs: ttHasBands ? 'auto' : 'hidden',
          xl: overflowSettingForXlScreens()
        }
      }}>
      <Table sx={styles.table} aria-label="spanning table">
        {colGroup()}
        {tableHead()}
        {tableRows()}
      </Table>
    </TableContainer>
  );
}

export default BandsTable;
