/* eslint-disable no-use-before-define */
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import { useMemo, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { merge } from 'lodash';
import { Button } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';
import SalesLeadAPI from '../../api/SalesLeadAPI';
import SchoolAPI from '../../api/SchoolAPI';
import externalPaths from '../../consts/externalPaths';

const eightAM = dayjs().set('hour', 8).startOf('hour');
const three45PM = dayjs().set('hour', 15).set('minute', 45).startOf('minute');
const disableWeekends = (date) => [0, 6].includes(date.get('day'));

const CampaignForm = ({ marketingParams, redirectUrl, formType }) => {
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [failedFormSubmission, setFailedFormSubmission] = useState(false);
  const [successFormSubmission, setSuccessFormSubmission] = useState(false);
  const [schools, setSchools] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [schoolFetchError, setSchoolFetchError] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);

  const defaultFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    jobTitle: '',
    school: '',
    optedInForMarketing: true,
    acknowledgedPrivacyPolicy: false,
    dateTime: null
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const {
    email,
    firstName,
    lastName,
    jobTitle,
    school,
    phoneNumber,
    optedInForMarketing,
    acknowledgedPrivacyPolicy,
    dateTime
  } = formValues;

  const optedInForMarketingObject = useMemo(
    () => ({ opted_in_for_marketing: optedInForMarketing }),
    [optedInForMarketing]
  );

  const preferredCallBackTimeObject = useMemo(
    () => ({ preferred_call_back_time: dateTime ? new Date(dateTime).toISOString() : undefined }),
    [dateTime]
  );

  const salesLeadableAttributes = useMemo(
    () => ({
      ...marketingParams,
      data: merge(optedInForMarketingObject, preferredCallBackTimeObject)
    }),
    [optedInForMarketingObject, preferredCallBackTimeObject, marketingParams]
  );

  const emailValid = () => StringUtils.isEmailIsh(email);

  const formValid = () =>
    emailValid() &&
    firstName &&
    lastName &&
    jobTitle &&
    school &&
    (formType === 'with_date_time_picker' ? phoneNumber : true) &&
    acknowledgedPrivacyPolicy;

  const emailInvalid = showValidationErrors && !emailValid();

  const valueMissing = (name) => {
    if (!showValidationErrors) return false;

    if (!formValues[name]) return true;

    // Check that it contains something other than whitespace
    return !formValues[name].trim();
  };

  const mapResponseToOptions = (data) =>
    data
      .filter(({ name }) => !name.includes('(Closed)', name.length - 8))
      .map((item) => ({
        key: item.id,
        urn: item.id.toString(),
        name: item.name
      }));

  const search = (query) => {
    setSchoolFetchError(false);
    SchoolAPI.findSchool(query)
      .then((res) => {
        setSchools(mapResponseToOptions(res.data));
      })
      .catch(() => setSchoolFetchError(true));
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    if (query.length > 2) {
      search(query);
    } else {
      setSchools([]);
    }
  };

  const handleDateTimeChange = (newValue) => {
    setShowValidationErrors(false);
    setDateTimeError(false);
    setFormValues({ ...formValues, dateTime: newValue });
  };

  const firstNameField = () => (
    <TextField
      margin="normal"
      required
      sx={styles.textField}
      id="first-name"
      label="First Name"
      name="firstName"
      autoComplete="given-name"
      autoFocus
      onChange={handleInputChange}
      error={valueMissing('firstName')}
      helperText={valueMissing('firstName') ? 'Please enter your first name' : ''}
    />
  );

  const lastNameField = () => (
    <TextField
      margin="normal"
      required
      sx={styles.textField}
      id="last-name"
      label="Last Name"
      name="lastName"
      autoComplete="family-name"
      onChange={handleInputChange}
      error={valueMissing('lastName')}
      helperText={valueMissing('lastName') ? 'Please enter your last name' : ''}
    />
  );

  const emailField = () => (
    <TextField
      margin="normal"
      required
      sx={styles.textField}
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      type="email"
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter your school email address' : ''}
    />
  );

  const phoneNumberField = () => (
    <TextField
      margin="normal"
      required
      sx={styles.textField}
      id="phone-number"
      label="Phone Number"
      name="phoneNumber"
      autoComplete="tel"
      onChange={handleInputChange}
      error={valueMissing('phoneNumber')}
      helperText={valueMissing('phoneNumber') ? 'Please enter your phone number' : ''}
    />
  );

  const jobTitleField = () => (
    <TextField
      margin="normal"
      required
      sx={styles.textField}
      id="job-title"
      label="Job Title"
      name="jobTitle"
      autoComplete="job-title"
      onChange={handleInputChange}
      error={valueMissing('jobTitle')}
      helperText={valueMissing('jobTitle') ? 'Please enter your role or job title' : ''}
    />
  );

  const schoolField = () => (
    <Autocomplete
      sx={styles.autoComplete}
      id="schools-search"
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      options={schools}
      onInputChange={(event, newInputValue) => handleSearchChange(newInputValue)}
      isOptionEqualToValue={(option, value) => option.urn === value.urn}
      onChange={(event, newValue) => handleSelectingSchool(newValue)}
      noOptionsText={message()}
      onBlur={() => setSchoolFetchError(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="School"
          name="school"
          error={valueMissing('school') || schoolFetchError}
          helperText={valueMissing('school') ? 'Please choose a school' : ''}
        />
      )}
    />
  );

  const message = () => {
    if (searchQuery.length < 3) {
      return 'Type school name / postcode to search...';
    }

    if (schoolFetchError) return 'Error fetching schools';

    return 'No results found';
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const consideredValue = type === 'checkbox' ? checked : value;
    setShowValidationErrors(false);
    setFormValues({ ...formValues, [name]: consideredValue });
  };

  const handleSelectingSchool = (option) =>
    setFormValues({ ...formValues, school: option?.urn || '' });

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowValidationErrors(true);

    if (formValid()) {
      setFormLoading(true);

      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        job_title: jobTitle,
        urn: school,
        sales_leadable_type: 'MarketingFormSubmission',
        sales_leadable_attributes: salesLeadableAttributes
      };

      return SalesLeadAPI.create({ sales_lead: formData, redirect_url: redirectUrl })
        .then(() => {
          setFormValues(defaultFormValues);
          setSuccessFormSubmission(true);
        })
        .catch((e) => {
          setFailedFormSubmission(true);
          setErrorMsg(e.response?.data?.errors || e.message);
        })
        .finally(() => {
          setFormLoading(false);
        });
    }

    return null;
  };

  const dateTimeIsValid = () => {
    // If the user selects 11AM (a valid time) and then clicks on PM, turning it into an invalid time
    // the DateTimePicker onError prop gets triggered, flagging this as an error, which we store in the dateTimeError state
    if (dateTimeError) return false;

    // If the user presses Submit and we don't have a dateTime selected, it's valid, since it's not required
    if (showValidationErrors && !dateTime) return true;

    // Otherwise, if the user hasn't yet pressed on Submit, double-check when a date is selected that it is in the future
    // The user shouldn't be able to select past dates, but they can select today and a time that is earlier than the current time.
    // This is an MUI bug which has been fixed but only released in an alpha version https://github.com/mui/mui-x/pull/6226
    if (!dateTime) return true;
    return dateTime.isAfter(dayjs());
  };

  const tcCheckbox = () => (
    <FormControl
      required
      error={showValidationErrors && !acknowledgedPrivacyPolicy}
      component="fieldset"
      sx={styles.checkBoxControl}
      variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={acknowledgedPrivacyPolicy}
              onChange={handleInputChange}
              name="acknowledgedPrivacyPolicy"
            />
          </div>
        }
        label={
          <Typography variant="body" component="p">
            I accept the Rocksteady Privacy Policy.{' '}
            <a href={externalPaths.PRIVACY_POLICY} target="_blank" rel="noreferrer">
              <Typography variant="bodyLink">View privacy policy.</Typography>
            </a>
          </Typography>
        }
      />
      {showValidationErrors && !acknowledgedPrivacyPolicy && (
        <FormHelperText>Please accept the policy first</FormHelperText>
      )}
    </FormControl>
  );

  const marketingCheckbox = () => (
    <FormControl component="fieldset" sx={styles.checkBoxControl} variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={optedInForMarketing}
              onChange={handleInputChange}
              name="optedInForMarketing"
            />
          </div>
        }
        label={
          <Typography variant="body" component="p">
            I consent to receiving marketing emails from Rocksteady Music School.
          </Typography>
        }
      />
    </FormControl>
  );

  // Note: to discuss with Marketing if they'd rather have it as separate date and time fields
  const dateTimeField = () => (
    <DateTimePicker
      open={openDatePicker}
      onOpen={() => setOpenDatePicker(true)}
      onClose={() => setOpenDatePicker(false)}
      ampm
      label="Preferred Date &amp; Time for Call Back"
      disablePast
      minutesStep={15}
      minTime={eightAM}
      maxTime={three45PM}
      onError={(reason) => {
        if (reason) setDateTimeError(true);
      }}
      shouldDisableDate={disableWeekends}
      value={dateTime}
      onChange={handleDateTimeChange}
      InputProps={{
        style: { backgroundColor: 'white' }
      }}
      showToolbar={false}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={styles.dateTimePicker}
          name="dateTime"
          error={!dateTimeIsValid()}
          helperText={
            !dateTimeIsValid() &&
            'Please choose a future date between Monday-Friday 8:00AM - 3:45PM'
          }
          // Disable the default editable behaviour of the text field
          // and instead make it so that on click it opens the picker
          onClick={() => setOpenDatePicker(true)}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
        />
      )}
    />
  );

  const renderErrorAlert = () => (
    <Alert severity="error" sx={styles.alert}>
      {Array.isArray(errorMsg) ? errorMsg.join('. ') : errorMsg}
    </Alert>
  );

  const thanksForSubmitting = () => (
    <Box>
      <Alert severity="success" sx={styles.alert}>
        Thanks for submitting!
      </Alert>
    </Box>
  );

  const campaignForm = () => {
    return (
      <div>
        {failedFormSubmission && renderErrorAlert()}
        {successFormSubmission ? (
          thanksForSubmitting()
        ) : (
          <Box component="form" sx={styles.formGroup} noValidate>
            {firstNameField()}
            {lastNameField()}
            {emailField()}
            {formType === 'with_date_time_picker' && phoneNumberField()}
            {jobTitleField()}
            {schoolField()}
            {formType === 'with_date_time_picker' && dateTimeField()}
            {marketingCheckbox()}
            {tcCheckbox()}

            <Button
              type="submit"
              label="Submit Form"
              sx={styles.submitBtn}
              loading={formLoading}
              color="secondary"
              onClick={handleSubmit}
            />
          </Box>
        )}
      </div>
    );
  };

  return campaignForm();
};

const styles = {
  formGroup: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' },
  checkBoxControl: { display: 'block', width: '100%' },
  submitBtn: { width: 'fit-content', mt: 24, mb: 23 },
  alert: { mb: 10 },
  textField: { mb: 10, mr: 20, width: { md: '47%', xs: '100%' } },
  autoComplete: { mr: 0, mt: 16, width: { md: '47%', xs: '100%' } },
  dateTimePicker: { mt: 16, width: { md: '47%', xs: '100%' } }
};

export default CampaignForm;
