/* eslint-disable no-use-before-define */
import { Box, Slide } from '@mui/material';

export default function AuthContainer({
  animationSlidesIn,
  containerRef,
  onExitedCallback,
  children
}) {
  const content = () => (
    <Slide
      direction="right"
      in={animationSlidesIn}
      container={containerRef.current}
      mountOnEnter
      unmountOnExit
      easing={{ enter: 'cubic-bezier(0.3, 0.1, 0.3, 1.0)' }}
      timeout={400}
      onExited={onExitedCallback}>
      <Box sx={styles.contentContainer}>{children}</Box>
    </Slide>
  );

  return content();
}

const styles = {
  contentContainer: {
    bgcolor: 'whiteWedding.main',
    borderRadius: '25px',
    minHeight: 729,
    boxSizing: 'border-box',
    padding: { lg: 40, md: 35, sm: 30, xs: 20 },
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)'
  }
};
