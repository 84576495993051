/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class SalesLeadAPI {
  create = (params) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/sales_leads.json`,
      config: { data: params }
    });
}

export default new SalesLeadAPI();
