/* eslint-disable class-methods-use-this */
class DateUtils {
  today = () => new Date();

  isoFormatDate = (date) => date.toLocaleDateString('en-GB').split('/').reverse().join('-');

  // Takes a string, returns an array as follows: ['Mon', '6th Apr', '2022']
  formattedDateParts = (date) => {
    const d = new Date(date);
    const options = { weekday: 'short', year: 'numeric', day: 'numeric', month: 'short' };

    const parts = d.toLocaleString('en', options).split(', '); // Returns an array as follows: ['Mon', 'Apr 6', '2022']
    const dayOfMonth = `${this.dateOrdinal(parts[1].split(' ')[1])} ${parts[1].split(' ')[0]}`;
    parts[1] = dayOfMonth;
    return parts;
  };

  stringToMonthYear = (str) => {
    // Takes a date string and returns the month and year i.e. "February 2022"
    const date = new Date(str);
    return `${date.toLocaleString('en-US', {
      month: 'long'
    })} ${date.getFullYear()}`;
  };

  dateOrdinal = (dayOfMonth) => {
    const dom = Number(dayOfMonth);

    if (dom === 31 || dom === 21 || dom === 1) return `${dom}st`;
    if (dom === 22 || dom === 2) return `${dom}nd`;
    if (dom === 23 || dom === 3) return `${dom}rd`;
    return `${dom}th`;
  };

  nWeeksFromNow = (weeks) => new Date(this.today().getTime() + weeks * 7 * 24 * 60 * 60 * 1000);

  nWeeksBeforeNow = (weeks) => new Date(this.today().getTime() - weeks * 7 * 24 * 60 * 60 * 1000);

  nWeeksFromDate = (initDay, weeks) => new Date(initDay.setDate(initDay.getDate() + weeks * 7));

  nWeeksBeforeDate = (initDay, weeks) => new Date(initDay.setDate(initDay.getDate() - weeks * 7));

  getNextDayOfTheWeek = (refDate, dayName) => {
    const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(
      dayName.toLowerCase()
    );

    if (dayOfWeek < 0) return undefined;
    const nextDate = new Date(refDate);
    nextDate.setDate(nextDate.getDate() + ((dayOfWeek + 7 - nextDate.getDay()) % 7));

    return nextDate;
  };

  getDatesInRange = (startDate, stopDate, daySlot) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const closestDaySlot = this.getNextDayOfTheWeek(currentDate, daySlot);

    currentDate = closestDaySlot;

    while (currentDate.getTime() <= new Date(stopDate).getTime()) {
      dates.push(this.isoFormatDate(currentDate));
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    }
    return dates;
  };

  oneDayInMilliseconds = () => 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  isBeforeToday = (date) => {
    if (!date) return false;

    const givenDate = new Date(date);
    givenDate.setHours(23, 59, 59, 999); // set to end of given date

    return givenDate < this.today();
  };
}

export default new DateUtils();
