/* eslint-disable no-use-before-define */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Button } from '@rocksteady-music-school/rms-ui';
import UserAPI from '../../api/UserAPI';
import StringUtils from '../../utils/StringUtils';

const ChangePasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [passwordValues, setPasswordValues] = useState({
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: ''
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const [mismatchingPasswords, setMismatchingPasswords] = useState(false);
  const [error, setError] = useState(null);

  const newPasswordValid = () =>
    !StringUtils.passwordIsNotComplexEnough(passwordValues.newPassword);

  const passwordConfirmationValid = () =>
    !StringUtils.passwordIsNotComplexEnough(passwordValues.passwordConfirmation);

  const newPasswordInvalid = showValidationErrors && !newPasswordValid();

  const passwordConfirmationInvalid = showValidationErrors && !passwordConfirmationValid();

  const formValid = () =>
    passwordValues.currentPassword && newPasswordValid() && passwordConfirmationValid();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowValidationErrors(false);
    setMismatchingPasswords(false);
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const errorHelperText = (invalidField, helperText) => {
    if (mismatchingPasswords) {
      return 'The passwords do not match';
    }
    if (invalidField) {
      return helperText;
    }
    return '';
  };

  const currentPasswordField = () => (
    <TextField
      sx={styles.input}
      value={passwordValues.currentPassword}
      margin="normal"
      required
      name="currentPassword"
      label="Current Password"
      type={showCurrentPassword ? 'text' : 'password'}
      id="current-password"
      autoComplete="current-password"
      onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ zIndex: 1 }}
              aria-label="toggle current password visibility"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
              {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  const newPasswordField = () => (
    <TextField
      sx={styles.input}
      value={passwordValues.newPassword}
      margin="normal"
      required
      name="newPassword"
      label="New Password"
      type={showNewPassword ? 'text' : 'password'}
      id="new-password"
      autoComplete="new-password"
      onChange={handleInputChange}
      error={mismatchingPasswords || newPasswordInvalid}
      helperText={errorHelperText(newPasswordInvalid, 'Please enter a new valid password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ zIndex: 1 }}
              aria-label="toggle new password visibility"
              onClick={() => setShowNewPassword(!showNewPassword)}>
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  const confirmPasswordField = () => (
    <TextField
      sx={styles.input}
      value={passwordValues.passwordConfirmation}
      margin="normal"
      required
      name="passwordConfirmation"
      label="Confirm Password"
      type={showConfirmPassword ? 'text' : 'password'}
      id="confirm-password"
      autoComplete="confirm-password"
      onChange={handleInputChange}
      error={mismatchingPasswords || passwordConfirmationInvalid}
      helperText={errorHelperText(passwordConfirmationInvalid, 'Please confirm your password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ zIndex: 1 }}
              aria-label="toggle confirm password visibility"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  const updatePassword = async () => {
    const data = {
      password: {
        current_password: passwordValues.currentPassword,
        password: passwordValues.newPassword,
        password_confirmation: passwordValues.passwordConfirmation
      }
    };
    try {
      await UserAPI.updatePassword(data);
      setShowValidationErrors(false);
      enqueueSnackbar('Done! Your password has been successfully changed.', { variant: 'success' });
      setPasswordValues({
        currentPassword: '',
        newPassword: '',
        passwordConfirmation: ''
      });
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowValidationErrors(true);
    setError(null);
    if (passwordValues.newPassword !== passwordValues.passwordConfirmation) {
      setMismatchingPasswords(true);
      return;
    }

    if (formValid()) {
      setLoading(true);
      updatePassword();
    }
  };

  const renderAlert = (type, message) => (
    <Alert severity={type} sx={{ mt: 15 }}>
      {message}
    </Alert>
  );

  const submitButton = () => (
    <Button
      type="submit"
      label="Save Changes"
      color="secondary"
      style={styles.secondaryBtn}
      loading={loading}
      disabled={
        !(
          passwordValues.currentPassword &&
          passwordValues.newPassword &&
          passwordValues.passwordConfirmation
        )
      }
    />
  );

  const titleSection = () => (
    <Stack spacing={8}>
      <Typography variant="bodyBold" component="p">
        Change Your Password
      </Typography>
      <Typography variant="body" component="p" color="fadeToGrey.main">
        Password should be over 8 characters long and include at least one lowercase letter,{' '}
        uppercase letter and digit.
      </Typography>
    </Stack>
  );

  const form = () => (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <FormGroup>
        {currentPasswordField()}
        {newPasswordField()}
        {confirmPasswordField()}
        {error &&
          renderAlert(
            'error',
            `An error occurred: ${error}. Please contact us if the problem persists.`
          )}
        {submitButton()}
      </FormGroup>
    </Box>
  );

  const content = () => (
    <Stack spacing={24}>
      {titleSection()}
      {form()}
    </Stack>
  );

  return content();
};

const styles = {
  secondaryBtn: { width: 'fit-content', marginTop: 24 },
  input: {
    marginTop: 8,
    width: { xs: '100%', md: '70%' },
    maxWidth: { xs: 500, lg: 260 },
    '& .MuiOutlinedInput-input': {
      zIndex: 1
    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'white'
    }
  }
};

export default ChangePasswordForm;
