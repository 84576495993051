/* eslint-disable no-use-before-define */

import { Box } from '@mui/material';
import fgfFooter from '../../assets/images/individual_quotes.png';
import testimonials from '../../consts/testimonials';
import Carousel from '../Carousel/Carousel';

export default function FGFFooter() {
  const footer = () => (
    <Box sx={styles.container}>
      <Carousel
        slides={testimonials.FGF}
        backgroundImg={fgfFooter}
        textColor="primary.main"
        authorTextColor="fadeToGrey.main"
        textFontStyle="h5"
        authorFontStyle="body"
        activeIndicatorColor="primary.main"
        inactiveIndicatorColor="paintItBlack.40"
        indicatorHoverColor="secondary.main"
      />
    </Box>
  );

  return footer();
}

const styles = {
  container: {
    padding: { xs: '28px 10px', lg: '28px 180px' },
    backgroundColor: 'blueSuedeShoes.5',
    borderRadius: '25px'
  }
};
