/* eslint-disable no-use-before-define */

import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';
import SetPasswordForm from '../components/SetPasswordForm/SetPasswordForm';

export default function SetPasswordPage() {
  const [slideInRef] = useOutletContext();

  const heading = (text) => (
    <Typography variant="h1" mb={24} mt={50}>
      {text}
    </Typography>
  );

  return (
    <AuthContainer containerRef={slideInRef} animationSlidesIn>
      <Box>
        {heading('new password')}
        <SetPasswordForm />
      </Box>
    </AuthContainer>
  );
}
