/* eslint-disable react/prop-types */
import { Typography, Box, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '@rocksteady-music-school/rms-ui';
import DateUtils from '../../utils/DateUtils';
import { withEnrollments, noEnrollments } from './MatchFundingTexts';

function MatchedFundingModal({ open, onClose, placesInfo, setFormVisible }) {
  const { kids, numOfKids } = placesInfo;
  const { bursary_kids: bursaryKids, school_funded_kids: schoolFundedKids } = kids;

  const modalTextLine = (text, variant) => (
    <Typography variant={variant} display="block" mt={8}>
      {text}
    </Typography>
  );

  const kidsTable = (kidList) => (
    <Box marginBottom={24}>
      {kidList?.map((k, index) => (
        <Box key={uuidv4()} marginBottom={kidList.length - 1 === index ? 0 : 16}>
          {/* Row with the pupil's name */}
          <Grid container display="flex">
            <Grid item xs={6}>
              {modalTextLine('Pupil Name:', 'body')}
            </Grid>
            <Grid item xs={6}>
              {modalTextLine(k.name || '-', 'bodyBold')}
            </Grid>
          </Grid>

          {/* Row with the pupil's enrollment date */}
          <Grid container display="flex">
            <Grid item xs={6}>
              {modalTextLine('Year Registered:', 'body')}
            </Grid>
            <Grid item xs={6}>
              {modalTextLine(
                k.enrolled_at ? DateUtils.stringToMonthYear(k.enrolled_at) : '-',
                'bodyBold'
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );

  const modalTitle = 'Match-Funding Status';

  const bursaryKidsTable = () =>
    bursaryKids.length > 0 && (
      <>
        <Typography variant="h5" marginTop={16}>
          {`Bursary Pupil${bursaryKids.length > 1 ? 's' : ''} Details`}
        </Typography>
        {kidsTable(bursaryKids)}
      </>
    );

  const schoolFundedKidsTable = () =>
    schoolFundedKids.length > 0 && (
      <>
        <Typography variant="h5" marginTop={16}>
          {`School Funded Pupil${schoolFundedKids.length > 1 ? '(s)' : ''} Details`}
        </Typography>
        {kidsTable(schoolFundedKids)}
      </>
    );

  const modalContent = () => {
    if (numOfKids === 0) {
      return noEnrollments;
    }
    return (
      <>
        <Typography variant="bodyBold" component="p" color="greenOnions.140" marginBottom={16}>
          {`Thank you for supporting ${
            numOfKids > 1 ? `${numOfKids} pupils` : '1 pupil'
          } through match-funding.`}
        </Typography>
        {withEnrollments}
        {bursaryKidsTable()}
        {schoolFundedKidsTable()}
      </>
    );
  };

  const handleOnRegisterPupilClick = () => {
    onClose();
    setFormVisible();
  };

  const buttonProps = [
    { label: 'Register Pupil', color: 'secondary', onClick: handleOnRegisterPupilClick }
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      modalEntities={[
        {
          title: modalTitle,
          content: modalContent(),
          buttonProps
        }
      ]}
    />
  );
}

export default MatchedFundingModal;
