const externalPaths = Object.freeze({
  WWW: 'https://www.rocksteadymusicschool.com',
  WWW_CONTACTS: 'https://www.rocksteadymusicschool.com/contacts',
  TERMS_AND_CONDITIONS: 'https://www.rocksteadymusicschool.com/terms-conditions/',
  PRIVACY_POLICY: 'https://www.rocksteadymusicschool.com/policies/Rocksteady_Privacy_Policy.pdf',
  FACEBOOK: 'https://www.facebook.com/rocksteadymusicschool/',
  LINKEDIN: 'https://www.linkedin.com/company/rocksteady-music-school',
  TWITTER: 'https://twitter.com/RSMusicSchool',
  INSTAGRAM: 'https://www.instagram.com/rocksteadymusicschool/',
  ENQUIRY_FORM: 'https://schoolshotline.rocksteadymusicschool.com',
  BOOK_CALL_FORM:
    'https://outlook.office365.com/owa/calendar/RocksteadySchoolsExperienceSpecialist1@rocksteadymusicschool.com/bookings/'
});

export default externalPaths;
