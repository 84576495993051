import paths from './paths';
import userRoles from './userRoles';

const menuItems = Object.freeze([
  {
    path: paths.ROOT,
    text: 'Timetable',
    iconName: 'fa-calendar-alt',
    visible: [userRoles.ADMIN.roleName, userRoles.STAFF.roleName]
  },
  {
    path: paths.ROOT,
    text: 'Feel Good Friday',
    iconName: 'fa-laugh-beam',
    visible: [userRoles.BASIC.roleName, 'inactive']
  },
  {
    path: paths.BAND_LESSONS,
    text: 'In-School Band Lessons',
    iconName: 'microphone',
    visible: ['inactive']
  },
  {
    path: paths.CONTACTS,
    text: 'Contacts',
    iconName: 'fa-users',
    visible: [
      userRoles.ADMIN.roleName,
      userRoles.STAFF.roleName,
      userRoles.BASIC.roleName,
      'inactive'
    ]
  },
  {
    path: paths.MATCH_FUNDING,
    text: 'Match-Funding Scheme',
    iconName: 'fa-hand-holding-heart',
    visible: [userRoles.ADMIN.roleName]
  },
  {
    path: paths.IMPACT_REPORT,
    text: 'Impact Report',
    iconName: 'fa-chart-pie',
    visible: [userRoles.ADMIN.roleName, userRoles.STAFF.roleName]
  },
  {
    path: paths.FEEL_GOOD_FRIDAY,
    text: 'Feel Good Friday',
    iconName: 'fa-laugh-beam',
    visible: [userRoles.ADMIN.roleName, userRoles.STAFF.roleName]
  },
  {
    path: paths.KEY_RESOURCES,
    text: 'Key Resources',
    iconName: 'fa-file-alt',
    visible: [
      userRoles.ADMIN.roleName,
      userRoles.STAFF.roleName,
      userRoles.BASIC.roleName,
      'inactive'
    ]
  },
  {
    path: paths.ACCOUNT_SETTINGS,
    text: 'Account Settings',
    iconName: 'fa-cogs',
    visible: [
      userRoles.ADMIN.roleName,
      userRoles.STAFF.roleName,
      userRoles.BASIC.roleName,
      'inactive'
    ]
  }
]);

export default menuItems;
