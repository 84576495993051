/* eslint-disable no-use-before-define */
import { Box, Typography, Grid, keyframes } from '@mui/material';
import quotationMarks from '../../assets/images/auth_quotes.png';
import Carousel from '../Carousel/Carousel';
import testimonials from '../../consts/testimonials';

const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } });

export default function AuthInfoPanel() {
  const content = () => (
    <Box sx={styles.infoBox}>
      <Typography variant="h2" color="whiteWedding.main" mb={16}>
        Including: Feel Good Friday
      </Typography>
      <Grid container columnSpacing={33} rowSpacing={24}>
        <Grid item xs={12} sm={7}>
          <Typography variant="body" color="whiteWedding.main">
            For schools that do not yet take Rocksteady peripatetic in-school lessons, this is where
            you can access Feel Good Friday, completely free online whole class music lessons which
            cover a large part of the music curriculum.
            <br />
            <br />
            For schools that do take Rocksteady peripatetic in-school lessons, in addition to
            accessing Feel Good Friday, this is also where you can access your timetables, free
            bursary places, contacts and more.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} display="flex" flexDirection="column" justifyContent="center">
          <Carousel
            slides={testimonials.SIGN_IN}
            backgroundImg={quotationMarks}
            textColor="whiteWedding.main"
            authorTextColor="whiteWedding.main"
            textFontStyle="bodyBold"
            authorFontStyle="body"
            activeIndicatorColor="secondary.main"
            inactiveIndicatorColor="paintItBlack.10"
            indicatorHoverColor="primary.main"
          />
        </Grid>
      </Grid>
    </Box>
  );

  return content();
}

const styles = {
  infoBox: {
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '25px',
    background: 'rgba(255, 255, 255, 0.2)',
    paddingX: '32px',
    paddingY: '48px',
    marginLeft: { xs: 0, lg: 24 },
    marginTop: '24px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
    animation: `${fadeIn} 1s backwards`
  },
  quotation: {
    backgroundImage: `url(${quotationMarks})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '90px',
    display: 'flex',
    alignItems: 'center'
  }
};
