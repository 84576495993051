/* eslint-disable class-methods-use-this */
import axios from 'axios';
import JWTManager from './JWTManager';
import paths from '../consts/paths';
import WindowUtils from './WindowUtils';

class AuthenticatedHTTPWrapper {
  configWithAuthHeader = (config = {}) => ({
    ...config,
    headers: {
      Authorization: JWTManager.getToken()
    }
  });

  handleError = (errors) => {
    if (errors.response && errors.response.status === 401) {
      JWTManager.removeToken();
      WindowUtils.replaceLocation(`${paths.LOG_IN}?signInTimedOut=true`);
    }
  };

  patch = ({ url, data, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .patch(url, data, this.configWithAuthHeader(config))
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          this.handleError(errors);
          reject(errors);
        });
    });

  get = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, this.configWithAuthHeader(config))
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          this.handleError(errors);
          reject(errors);
        });
    });

  delete = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .delete(url, this.configWithAuthHeader(config))
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          this.handleError(errors);
          reject(errors);
        });
    });

  post = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, config.data, this.configWithAuthHeader(config))
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          this.handleError(errors);
          reject(errors);
        });
    });
}

export default new AuthenticatedHTTPWrapper();
