const contactDetails = Object.freeze({
  SCHOOLS_EMAIL: 'schools@rocksteadymusicschool.com',
  SCHOOLS_TEL: '0330 113 0401',
  LIVE_EMAIL: 'live@rocksteadymusicschool.com',
  LONG_SCHOOLS_TEL: '0330 113 0401 (Mon-Fri 8am-4pm)',
  PP_EMAIL: 'pupilpremium@rocksteadymusicschool.com',
  TELL_ME_MORE_EMAIL: 'tellmemore@rocksteadymusicschool.com'
});

export default contactDetails;
