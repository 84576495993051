/* eslint-disable react/no-danger */
/* eslint-disable no-use-before-define */
import { useParams, useSearchParams } from 'react-router-dom';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { pickBy } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { Box, CircularProgress, FormGroup } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import { Button, Page } from '@rocksteady-music-school/rms-ui';
import { PrismicRichText } from '../components/PrismicRichText/PrismicRichText';
import CampaignForm from '../components/CampaignForm/CampaignForm';
import ErrorPage from './ErrorPage';

// placement & site_source_name both Meta supported param keys
// utm = "Urchin Tracking Module" params https://en.wikipedia.org/wiki/UTM_parameters
// rsm = "RockSteadyMusic" params, in-house tracking params
const PARAM_KEY_PATTERN = /^(placement|site_source_name)$|^(utm|rsm)_.+/;

export default function CampaignPage() {
  const { uid } = useParams();
  const [searchParams] = useSearchParams();

  const [document, { state }] = usePrismicDocumentByUID('campaign', uid);

  const permittedMarketingParams = useMemo(
    () => pickBy(Object.fromEntries(searchParams), (_val, key) => PARAM_KEY_PATTERN.test(key)),
    [searchParams]
  );

  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const prismicSlices = document?.data?.body;

  const prismicFormData = document?.data?.body?.find((slice) => slice.slice_type === 'form');

  useEffect(() => {
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load();
    }
  });

  // NOTE: the various sections should be formatted using Storybook components, so that we can easily switch to new
  // designs after the brand update.

  const heroSection = (title, paragraph, buttonLabel, key) => {
    return (
      <Box key={key} style={styles.heroSection}>
        <PrismicRichText field={title} />
        <PrismicRichText field={paragraph} />
        {formLinkButton(buttonLabel)}
      </Box>
    );
  };

  const bodySection = (heading, description, buttonLabel, videoUrl, twitterUrl, key) => {
    return (
      <Box key={key} style={styles.bodySection}>
        <PrismicRichText field={heading} color="#000000" />
        <PrismicRichText field={description} />
        {formLinkButton(buttonLabel)}
        {videoUrl?.embed_url && <Vimeo video={videoUrl.embed_url} style={styles.vimeo} />}
        {twitterUrl?.html && <div dangerouslySetInnerHTML={{ __html: twitterUrl.html }} />}
      </Box>
    );
  };

  const renderForm = () => {
    const formType = prismicFormData?.primary?.form_version;
    return (
      <Box ref={formRef} key="form" noValidate>
        <FormGroup sx={styles.formGroup}>
          <PrismicRichText field={prismicFormData?.primary?.form_title} color="#000000" />
          <PrismicRichText field={prismicFormData?.primary?.form_subtitle} />
          <CampaignForm
            marketingParams={permittedMarketingParams}
            redirectUrl={searchParams.get('redirect_url')}
            formType={formType}
          />
        </FormGroup>
      </Box>
    );
  };

  const formLinkButton = (label) => label && <Button label={label} onClick={scrollToForm} />;

  const renderSlices = () => {
    return prismicSlices?.map((slice, index) => {
      switch (slice.slice_type) {
        case 'hero_section':
          return heroSection(
            slice.primary.hero_title,
            slice.primary.hero_subtitle,
            slice.primary.hero_button,
            `hero_section-${index}`
          );
        case 'body_section':
          return bodySection(
            slice.primary.heading,
            slice.primary.description,
            slice.primary.button_label,
            slice.primary.video_url,
            slice.primary.twitter_url,
            `body_section-${index}`
          );
        case 'form':
          return renderForm();
        default:
          return null;
      }
    });
  };

  const content = () => (
    <Box mt={24} sx={styles.main}>
      <Page>{renderSlices()}</Page>
    </Box>
  );

  if (state === 'loading' || state === 'idle') {
    return <CircularProgress data-testid="circular-progress" />;
  }

  if (state === 'failed') {
    return <ErrorPage content="pageNotFound" />;
  }

  if (document) {
    return content();
  }
}

const styles = {
  main: { ml: { sm: 10, lg: 200 }, mr: { sm: 10, lg: 200 } },
  bodySection: { marginBottom: 40 },
  heroSection: { marginBottom: 40 },
  vimeo: { display: 'flex' }
};
