/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import DateUtils from '../../utils/DateUtils';

function BandSlot({ slot, allKids }) {
  const slotType = slot?.type;

  const assignedKid = allKids?.[slot?.id];

  const fadedText = (text) => <Typography variant="bodySecondary">{text}</Typography>;

  const blockedSlot = () => fadedText('Blocked slot');

  const instrumentChangeSlot = () => fadedText('Slot reserved for instrument change');

  const reservedKid = () => fadedText('Slot reserved');

  const hiddenKid = () => {
    const nextLessonDateParts = DateUtils.formattedDateParts(assignedKid.firstLessonDate);
    return fadedText(
      `${assignedKid.name} starts ${nextLessonDateParts[1]} ${nextLessonDateParts[2]}`
    );
  };

  const enrolledKid = () => (
    <Typography variant="body">
      {assignedKid.name}
      {fadedText(` Y${assignedKid.yearAndClass}`)}
    </Typography>
  );

  const kidSlot = () => {
    if (!assignedKid) return '';

    if (assignedKid.reserved) return reservedKid();
    if (assignedKid.enrolledButHiddenForNow) return hiddenKid();

    return enrolledKid();
  };

  const slotContent = () => {
    if (!slotType) return '';

    if (slotType === 'blocked_slot') return blockedSlot();
    if (slotType === 'instrument_change') return instrumentChangeSlot();
    if (slotType === 'kid') return kidSlot();

    return '';
  };

  return slotContent();
}

export default BandSlot;
