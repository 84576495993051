import PropTypes from 'prop-types';
import { PrismicRichText as BasePrismicRichText } from '@prismicio/react';
import { Typography } from '@rocksteady-music-school/rms-ui';

const defaultComponents = {
  heading1: ({ children }) => (
    <Typography variant="h1" color="#000000">
      {children}
    </Typography>
  ),
  heading2: ({ children }) => (
    <Typography variant="h2" color="#000000">
      {children}
    </Typography>
  ),
  heading4: ({ children }) => (
    <Typography variant="h4" color="#000000">
      {children}
    </Typography>
  )
};

export function PrismicRichText({ components, ...props }) {
  return <BasePrismicRichText components={{ ...defaultComponents, ...components }} {...props} />;
}
PrismicRichText.propTypes = {
  components: PropTypes.objectOf(PropTypes.elementType)
};

PrismicRichText.defaultProps = {
  components: {}
};

export default PrismicRichText;
