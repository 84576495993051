/* eslint-disable no-use-before-define */
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useSnackbar } from 'notistack';

import playIcon from '../../assets/images/play-icon.svg';
import CookieUtils from '../../utils/CookieUtils';

export default function BandLessonsVideo() {
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoHasError, setVideoHasError] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (videoHasError) {
      enqueueSnackbar(
        'There was an error with loading the video on this page. Please try refreshing the page and ensure your IT department allows Vimeo content.',
        { variant: 'error', autoHideDuration: 8000 }
      );
    }
  }, [videoHasError]);

  const displayPlayButton = () => {
    if (isMobile) return false;
    if (!player) return false;
    if (videoPlaying) return false;
    return true;
  };

  const playButton = () => (
    <Box
      onClick={() => (player ? player.play() : null)}
      className="playButton"
      component="img"
      alt="playIcon"
      src={playIcon}
      sx={{ ...styles.playIcon, display: displayPlayButton() ? 'inline-block' : 'none' }}
    />
  );

  const vimeoVideo = () => (
    <Vimeo
      className="bandLessonsVideo"
      video={513312781}
      key={513312781}
      playsInline={false}
      responsive
      onReady={(playerObj) => setPlayer(playerObj)}
      onPlay={() => setVideoPlaying(true)}
      onEnd={() => setVideoPlaying(false)}
      onPause={() => setVideoPlaying(false)}
      onLoaded={() => setVideoLoaded(true)}
      onError={() => setVideoHasError(true)}
      dnt={!CookieUtils.analyticsConsentGiven()}
      style={{
        display: videoLoaded ? 'inherit' : 'none'
      }}
    />
  );

  const videoContainer = () => {
    return (
      <Box marginY={40}>
        {videoLoaded ? null : <Skeleton className="videoSkeleton" variant="rectangular" />}

        <Box sx={{ position: 'relative' }}>
          {vimeoVideo()}
          {playButton()}
        </Box>
      </Box>
    );
  };

  return videoContainer();
}

const styles = {
  playIcon: {
    position: 'absolute',
    maxHeight: '33%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '0.7',
    transitionProperty: 'opacity',
    transitionDuration: '0.3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: '1',
      transitionProperty: 'opacity',
      transitionDuration: '0.3s'
    }
  }
};
