import contactDetails from '../../consts/contactDetails';
import './outdatedbrowsercontent.css';

export default function OutdatedBrowserContent() {
  return (
    <div className="outdated-container">
      <div className="outdated-text-content">
        <h1>
          Hi there. <br />
          It looks like you&apos;re using an outdated browser.
        </h1>
        <h5 style={{ marginTop: '25px' }}>
          For a safer and faster user experience, please use a modern browser like Chrome, Firefox,
          Safari, Opera or Edge.
        </h5>
        <h3 style={{ marginTop: '46px' }}>Contact us</h3>
        <p style={{ marginTop: '26px' }}>
          Got any questions? Please contact{' '}
          <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>the Schools Hotline</a> or call us at{' '}
          <a href={`mailto:${contactDetails.SCHOOLS_TEL}`}>{contactDetails.SCHOOLS_TEL}</a> for
          help.
        </p>
      </div>
    </div>
  );
}
