/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Icon, Stack, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Modal } from '@rocksteady-music-school/rms-ui';
import response from '../../consts/actions';
import TimetableAPI from '../../api/TimetableAPI';
import StringUtils from '../../utils/StringUtils';

export default function updateEmailsSection({
  timetableId,
  noUnsavedChanges,
  updatedListOfEmailsWithIds,
  setUpdatedListOfEmailsWithIds,
  setTimetableData,
  setEmailModalVisible,
  setUnsavedChangesModalVisible,
  unsavedChangesModalVisible
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateEmailsRequestStatus, setUpdateEmailsRequestStatus] = useState(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const emailEmptyOrValid = (email) => {
    // The email field can be empty, it won't get saved to the DB if it's an empty string
    // and there's no point in making the user remove the new email field before saving other new addresses
    if (!email) return true;

    return StringUtils.isEmailIsh(email);
  };

  const flagEmailInvalid = (email) => showValidationErrors && !emailEmptyOrValid(email);

  const formValid = () => {
    const formContainsInvalidEmails = updatedListOfEmailsWithIds.some(
      ({ email }) => !emailEmptyOrValid(email)
    );
    return !formContainsInvalidEmails;
  };

  const disableSavingChanges = () =>
    noUnsavedChanges || updateEmailsRequestStatus === response.LOADING;

  const addNewEmailField = () => {
    const emailListCopy = [...updatedListOfEmailsWithIds];
    emailListCopy.push({ id: uuidv4(), email: '' });

    setUpdatedListOfEmailsWithIds(emailListCopy);
  };

  const emailAddressesWithoutIds = () => {
    const emailListCopy = [...updatedListOfEmailsWithIds];

    return emailListCopy.map((item) => item.email);
  };

  const disableAddingNewEmailFields = emailAddressesWithoutIds().includes('');

  const saveChanges = () => {
    setShowValidationErrors(true);

    if (formValid()) {
      setShowValidationErrors(false);
      setUpdateEmailsRequestStatus(response.LOADING);
      TimetableAPI.updateTTData(timetableId, {
        timetable: { email_addresses_for_pdf: _.uniq(emailAddressesWithoutIds()).join(', ') }
      })
        .then((res) => {
          setTimetableData(res.data);
          setUpdateEmailsRequestStatus(response.SUCCESS);
          setEmailModalVisible(false);
          enqueueSnackbar('The automated email list has been successfully updated.', {
            variant: 'success'
          });
        })
        .catch((e) => {
          const error = e.response?.data?.errors || e.message;

          setUpdateEmailsRequestStatus(response.ERROR);
          setErrorMsg(error);
        });
    }
  };

  const handleUpdatingEmailAddress = (e, id) => {
    setShowValidationErrors(false);

    const { value } = e.target;

    const matchesId = (element) => element.id === id;

    const index = updatedListOfEmailsWithIds.findIndex(matchesId);
    const newEmails = [...updatedListOfEmailsWithIds];

    if (index !== -1) {
      newEmails[index].email = value;
    }
    setUpdatedListOfEmailsWithIds(newEmails);
  };

  const removeEmailAddress = (id) => {
    const matchesId = (element) => element.id === id;

    const index = updatedListOfEmailsWithIds.findIndex(matchesId);
    const emailListCopy = [...updatedListOfEmailsWithIds];

    if (index !== -1) {
      const filteredEmails = emailListCopy.filter((item) => item.id !== id);
      setUpdatedListOfEmailsWithIds(filteredEmails);
    }
  };

  const listOfEmails = () =>
    updatedListOfEmailsWithIds?.map(({ email, id }) => (
      <Stack
        key={id}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={12}
        mb={16}>
        <TextField
          fullWidth
          label="Email Address"
          name="email"
          type="email"
          value={email}
          error={flagEmailInvalid(email)}
          helperText={flagEmailInvalid(email) ? 'Please enter a valid email address' : ''}
          onChange={(e) => handleUpdatingEmailAddress(e, id)}
          sx={{ width: 340, mb: 0 }}
        />
        <Box
          height={48}
          display="flex"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={() => removeEmailAddress(id)}>
          <Icon sx={{ color: '#4A5568', height: 'fit-content' }}>cancel</Icon>
        </Box>
      </Stack>
    ));

  const alertMsg = () => {
    if (Array.isArray(errorMsg)) {
      return (
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 12 }}>
          {errorMsg.map((er) => (
            <li key={uuidv4()}>{er}</li>
          ))}
        </ul>
      );
    }
    return errorMsg;
  };

  const renderErrorAlert = () => (
    <Alert severity="error" sx={{ mb: 16 }}>
      {Array.isArray(errorMsg) && <AlertTitle>There were some errors with the request</AlertTitle>}
      {alertMsg()}
    </Alert>
  );

  const buttonPropsMainModal = [
    {
      label: 'Add Email',
      onClick: addNewEmailField,
      iconName: 'add',
      disabled: disableAddingNewEmailFields
    },
    {
      label: 'Save Changes',
      onClick: saveChanges,
      color: 'secondary',
      disabled: disableSavingChanges(),
      loading: updateEmailsRequestStatus === response.LOADING
    }
  ];

  const buttonPropsUnsavedChanges = [
    {
      label: 'Discard Changes',
      onClick: () => setEmailModalVisible(false),
      outlined: true
    },
    {
      label: 'Cancel',
      onClick: () => setUnsavedChangesModalVisible(false)
    }
  ];

  const unsavedChangesModal = () => (
    <Modal
      open={unsavedChangesModalVisible}
      onClose={() => setUnsavedChangesModalVisible(false)}
      modalEntities={[
        {
          title: 'Unsaved Changes',
          content: (
            <Typography variant="body">
              All the changes you&apos;ve made have not been saved. Are you sure you want to quit?
            </Typography>
          ),
          buttonProps: buttonPropsUnsavedChanges
        }
      ]}
    />
  );

  const section = {
    title: 'Edit Automated Email List',
    content: (
      <>
        {updateEmailsRequestStatus === response.ERROR && renderErrorAlert()}

        <Typography variant="body" component="p" mb={24}>
          Copies of the timetable are automatically emailed the day before a lesson is due to take
          place. Click here to update who is emailed a copy of the timetable.
        </Typography>
        {listOfEmails()}
        {unsavedChangesModal()}
      </>
    ),
    buttonProps: buttonPropsMainModal,
    justifyButtons: 'space-between'
  };

  return section;
}
