/* eslint-disable no-use-before-define */
import { Box, Typography } from '@mui/material';
import { Link, useOutletContext } from 'react-router-dom';
import { Button } from '@rocksteady-music-school/rms-ui';
import LoginForm from '../components/LoginForm/LoginForm';
import paths from '../consts/paths';
import AuthContainer from '../components/AuthContainer';

export default function LoginPage() {
  const [slideInRef] = useOutletContext();

  const heading = () => (
    <Typography variant="h1" sx={styles.heading}>
      Welcome to Rocksteady Schools Portal
    </Typography>
  );

  const text = () => (
    <Box mb={20}>
      <Typography variant="body" component="p">
        Rocksteady Music School is the world&apos;s largest rock school. The Schools Portal is
        available for any UK Primary School to access <em>Feel Good Friday</em>. If your school is
        taking Rocksteady peripatetic lessons, you can also access everything you need here too,
        such as timetables and contact information.
      </Typography>
    </Box>
  );

  const signUp = () => (
    <Box display="flex" alignItems="center" mt={30}>
      <Typography variant="bodyBold" mr={16} sx={{ fontSize: '20px' }}>
        New to Schools Portal?
      </Typography>
      <Link to={paths.SIGN_UP} style={{ textDecoration: 'none' }}>
        <Button label="Sign Up Now" />
      </Link>
    </Box>
  );

  const content = () => (
    <AuthContainer containerRef={slideInRef} animationSlidesIn>
      {heading()}
      {text()}
      <LoginForm />
      {signUp()}
    </AuthContainer>
  );

  return content();
}

const styles = {
  heading: { mb: 16, mt: 50 }
};
