/* eslint-disable react/prop-types */
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

// The 'fgf_tab' cookie is not being utilised at the mo due to major change in the fgf feature, and
// more thought needed around storing state/history of a user's watch history. Therefore have commented
// out that section of the table for now.

export default function CookiePolicyTable() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      ...theme.typography.bodyBold
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(210, 240, 251, 0.4)'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#D2F0FB'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  function createData(cookie, name, type, purpose) {
    return { cookie, name, type, purpose };
  }

  const rows = [
    createData(
      'Analytics Consent',
      'sp_analytics_consent',
      'Essential',
      <Typography variant="body">
        This cookie is used to remember your choice about analytics cookies on your device. If you
        have previously indicated a preference, it will be stored in this cookie.
      </Typography>
    ),
    createData(
      'Functionality Consent',
      'sp_functionality_consent',
      'Essential',
      <Typography variant="body">
        This cookie is used to remember your choice about site functionality cookies on your device.
        These cookies enhance your experience of the site. If you have previously indicated a
        preference, it will be stored in this cookie.
      </Typography>
    ),
    createData(
      'Vimeo',
      '__qca, __ssid, _abexps, _delighted_web, _fbp, _ga, _gcl_au, _mkto_trk, _uetvid, afUserId, auth_redirect, continuous_play_v3, has_logged_in, is_logged_in, sd_client_id, vimeo, vimeo_gdpr_optin, player, vuid',
      'Optional',
      <Typography variant="body">
        We embed videos from our official Vimeo channel. When you press play Vimeo will drop third
        party cookies to enable the video to play and to collect analytics data such as how long a
        viewer has watched the video. These cookies do not track individuals. See{' '}
        <a href="https://vimeo.com/cookie_policy" target="_blank" rel="noopener noreferrer">
          Vimeo Cookie Policy
        </a>{' '}
        for more info.
      </Typography>
    ),
    createData(
      'Google Analytics',
      '_ga,  _ga_<container-id>',
      'Optional',
      <Typography variant="body">
        We use Google Analytics to monitor how people use our websites and digital systems, for
        example, to see which pages are most popular. Google Analytics uses cookies to understand
        when users return to our sites. We use Google Analytics to track anonymous behaviour of
        users, and not to track individuals.
      </Typography>
    )
    // createData(
    //   'Feel Good Friday',
    //   'fgf_tab',
    //   'Optional',
    //   <Typography variant="body">
    //     This cookie is used to remember the last Feel Good Friday tab you were on. Since it is a
    //     functionality cookie, it will only be stored if you have given your consent for the site to
    //     use optional functionality cookies.
    //   </Typography>
    // )
  ];

  const cookieTable = () => (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Cookie</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Purpose</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell>{row.cookie}</StyledTableCell>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>{row.purpose}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return cookieTable();
}
