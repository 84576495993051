/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Typography } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LinkToPage({ text, showIcon, download }) {
  const iconDisplayStyle = () => (showIcon ? 'inline-block' : 'none');

  const downloadIcon = () => (
    <FontAwesomeIcon
      className="link-icon"
      icon={faCloudDownloadAlt}
      style={{
        ...styles.icon,
        marginLeft: 5,
        paddingLeft: 3,
        fontSize: 20,
        display: iconDisplayStyle()
      }}
    />
  );

  const arrowIcon = () => (
    <ArrowCircleRightOutlinedIcon
      className="link-icon"
      sx={{ ...styles.icon, marginLeft: 4, display: iconDisplayStyle() }}
    />
  );

  return (
    <Typography
      variant="h4"
      sx={styles.linkToPage}
      color={download ? 'primary.main' : 'paintItBlack.main'}
      display="flex"
      alignItems="center">
      {text} {download ? downloadIcon() : arrowIcon()}
    </Typography>
  );
}

const styles = {
  linkToPage: {
    zIndex: 1,
    whiteSpace: 'pre-line',
    '&:hover': {
      '& .link-icon': {
        marginLeft: '8px !important'
      }
    }
  },
  icon: {
    transition: '0.3s'
  }
};
