/* eslint-disable react/prop-types */

import { IconButton, styled, useTheme } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function dismissSnackButton(snackbarId) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <CloseOutlinedIcon />
    </IconButton>
  );
}

// This component serves as a wrapper around Notistack's SnackbarProvider,
// defined as such so that we are able to style the snackbars inline with our
// existing snackbar designs. Whilst Notistack do offer limited styling capabilities,
// using MUI's theme overriding system, our designs would not make that possible, hence
// requiring the styled() function.

export default function NotistackSnackbarProvider({ children }) {
  const theme = useTheme();
  const {
    breakpoints,
    palette: { blueSuedeShoes, greenOnions, rubyTuesday, yellowSubmarine },
    typography
  } = theme;

  const StyledSnackbarProvider = styled(SnackbarProvider)({
    '&.SnackbarItem-contentRoot': {
      borderRadius: '10px',
      flexWrap: 'nowrap',
      alignItems: 'flex-start'
    },
    '.snack-icon': {
      alignSelf: 'flex-start',
      marginRight: '16px'
    },
    '&.SnackbarItem-message': {
      ...typography.body,
      color: 'inherit'
    },
    '&.SnackbarItem-variantError': {
      backgroundColor: rubyTuesday[10],
      color: rubyTuesday[160]
    },
    '&.SnackbarItem-variantInfo': {
      backgroundColor: blueSuedeShoes[10],
      color: blueSuedeShoes[130]
    },
    '&.SnackbarItem-variantSuccess': {
      backgroundColor: greenOnions[20],
      color: greenOnions[170]
    },
    '&.SnackbarItem-variantWarning': {
      backgroundColor: yellowSubmarine[20],
      color: yellowSubmarine[160]
    },
    [breakpoints.up('sm')]: {
      width: 543
    }
  });

  const icons = {
    error: <ErrorOutlineIcon className="snack-icon" sx={{ color: rubyTuesday.main }} />,
    info: <InfoOutlinedIcon className="snack-icon" sx={{ color: blueSuedeShoes.main }} />,
    success: <TaskAltIcon className="snack-icon" sx={{ color: greenOnions[140] }} />,
    warning: <WarningAmberIcon className="snack-icon" sx={{ color: yellowSubmarine[150] }} />
  };

  // The default autoHideDuration value is 5000ms (5 seconds), so we will stick with that for now.
  // When making a call to enqueueSnackbar there are a number of props/options available, including
  // ability to override this autoHideDuration and set persist: true, so that it does not autoHide.
  // https://notistack.com/api-reference
  return (
    <StyledSnackbarProvider
      maxSnack={1}
      iconVariant={icons}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={dismissSnackButton}>
      {children}
    </StyledSnackbarProvider>
  );
}
