/* eslint-disable react/prop-types */
import Bugsnag from '@bugsnag/js';
import { useEffect } from 'react';
import { UserProviderContext } from '../contexts/UserContext';
import UserUtils from '../utils/UserUtils';

export default function UserProvider({ userData, setUserData, children }) {
  const userId = userData?.id;
  const schoolContactId = userData?.relationships?.school_contact?.data?.id;
  useEffect(() => Bugsnag.setUser(userId), [userId]);

  const firstName = userData?.attributes?.first_name;
  const lastName = userData?.attributes?.last_name;
  const email = userData?.attributes?.email;
  const optedInForMarketing = userData?.attributes?.opted_in_for_marketing;

  const role = userData?.attributes?.role;
  const isAdminOrStaff = UserUtils.isAdminOrStaff(role);
  const isAdmin = UserUtils.isAdmin(role);
  const isStaff = UserUtils.isStaff(role);
  const isBasic = UserUtils.isBasic(role);

  const timetables = userData?.attributes?.timetables || [];
  const isActiveSchool = userData?.attributes?.school?.status === 'active';
  const schoolUrn = userData?.attributes?.school?.urn;
  const schoolName = userData?.attributes?.school?.name;
  const schoolPostcode = userData?.attributes?.school?.postcode;
  const dpa = userData?.attributes?.school?.dpa;
  const dpaSignedBy = userData?.attributes?.school?.dpa_signed_by;
  const dpaSignedOn = userData?.attributes?.school?.dpa_signed_on;
  const dpaSigneeEmail = userData?.attributes?.school?.dpa_signee_email;
  const schoolLogo = userData?.attributes?.school?.logo;
  const isActiveSchoolAdminOrStaff = isActiveSchool && isAdminOrStaff;
  const activeSince = userData?.attributes?.school?.active_since;

  const currentSchoolYear = userData?.attributes?.current_school_year;
  const canHostRecruitmentLessons = userData?.attributes?.school?.can_host_recruitment_lessons;
  const satsPreference = userData?.attributes?.school?.sats_preference;
  const ks1Sats = userData?.attributes?.school?.ks1_sats_on_rs_days;
  const satsRoomNotes = userData?.attributes?.school?.sats_room_change_notes;
  const satsCutoffDate = userData?.attributes?.sats_preference_cutoff_date;

  const value = {
    setUserData,
    userId,
    schoolContactId,
    firstName,
    lastName,
    email,
    optedInForMarketing,
    role,
    isAdmin,
    isStaff,
    isBasic,
    isAdminOrStaff,
    timetables,
    isActiveSchool,
    schoolUrn,
    schoolName,
    schoolLogo,
    schoolPostcode,
    dpa,
    dpaSignedBy,
    dpaSignedOn,
    dpaSigneeEmail,
    isActiveSchoolAdminOrStaff,
    currentSchoolYear,
    canHostRecruitmentLessons,
    activeSince,
    satsPreferences: {
      sats_preference: satsPreference,
      ks1_sats_on_rs_days: ks1Sats,
      sats_room_change_notes: satsRoomNotes
    },
    satsCutoffDate
  };

  return <UserProviderContext properties={value}>{children}</UserProviderContext>;
}
