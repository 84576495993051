/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Box } from '@mui/material';

function ImgWrapper({ children, ...props }) {
  return (
    <Box
      gridColumn={{ xs: 'span 2', sm: 'span 1' }}
      width="100%"
      height="100%"
      borderRadius="10px"
      position="absolute"
      top={0}
      left={0}
      alignItems="center"
      {...props}>
      {children}
    </Box>
  );
}

export default function ClippedImgCardContainer({
  children,
  bgImg,
  clipPath,
  floatImgRight,
  shadowLeft,
  ...props
}) {
  const cardBackground = () => {
    return (
      <ImgWrapper
        sx={{
          ...styles.cardBackground,
          backgroundPosition: floatImgRight ? 'right' : 'inherit',
          backgroundImage: `url(${bgImg})`,
          clipPath
        }}
      />
    );
  };

  return (
    <Box
      gridColumn={{ xs: 'span 2', sm: 'span 1' }}
      minHeight={56}
      px={9}
      py={5}
      borderRadius="10px"
      display="flex"
      alignItems="center"
      position="relative"
      {...props}>
      <ImgWrapper
        sx={{ filter: `drop-shadow(${shadowLeft ? -4 : 4}px 0px 4px rgba(0, 0, 0, 0.1))` }}>
        {cardBackground()}
      </ImgWrapper>
      {children}
    </Box>
  );
}

const styles = {
  cardBackground: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }
};
