/* eslint-disable class-methods-use-this */
import { Cookies } from 'react-cookie';

class JWTManager {
  static cookies = () => new Cookies();

  removeToken = () => {
    JWTManager.cookies().remove('rsm_schools_portal_jwt', { path: '/' });
  };

  getToken = () => JWTManager.cookies().get('rsm_schools_portal_jwt');

  setToken = (token) =>
    new Promise(() => {
      JWTManager.cookies().set('rsm_schools_portal_jwt', token, { path: '/' });
    });
}

export default new JWTManager();
