/* eslint-disable class-methods-use-this */
import axios from 'axios';

class HTTPWrapper {
  patch = ({ url, data, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .patch(url, data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });

  get = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });

  delete = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .delete(url, config)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });

  post = ({ url, config } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, config.data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
}

export default new HTTPWrapper();
