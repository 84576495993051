/* eslint-disable no-use-before-define */
import {
  Alert,
  AlertTitle,
  Box,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button } from '@rocksteady-music-school/rms-ui';
import { useAuth } from '../../contexts/AuthContext';
import StringUtils from '../../utils/StringUtils';
import paths from '../../consts/paths';
import contactDetails from '../../consts/contactDetails';

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [loginValues, setLoginValues] = useState({ email: '', password: '' });
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [failedSignIn, setFailedSignIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const { email, password } = loginValues;

  const comingFromLocation = location.state?.from;

  // The url the user will be redirected after login is either the original pathname + query params
  // or the landing page
  const fromUrlWithParams = comingFromLocation?.pathname
    ? `${comingFromLocation.pathname}${comingFromLocation.search}`
    : '/';

  const emailValid = () => StringUtils.isEmailIsh(email);

  const passwordValid = () => !!password;

  const formValid = () => emailValid() && passwordValid();

  const emailInvalid = showValidationErrors && !emailValid();

  const passwordInvalid = showValidationErrors && !passwordValid();

  const emailField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      type="email"
      autoFocus
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter a valid email address' : ''}
    />
  );

  const passwordField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type={showPassword ? 'text' : 'password'}
      id="password"
      autoComplete="current-password"
      onChange={handleInputChange}
      error={passwordInvalid}
      helperText={passwordInvalid ? 'Please enter a password' : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowValidationErrors(false);
    setLoginValues({ ...loginValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowValidationErrors(true);

    if (formValid()) {
      setSignInLoading(true);

      signIn(email, password, setFailedSignIn, setErrorMsg, setSignInLoading, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(fromUrlWithParams, { replace: true });
      });
    }
  };

  const failedLoginMessage =
    'Excessive failed login attempts will result in your account being locked.' +
    ' If this happens we will send you an email with instructions on how to unlock your account.' +
    ` If this problem continues please contact the office on ${contactDetails.SCHOOLS_EMAIL}`;

  const renderErrorAlert = () => (
    <Alert severity="error" sx={styles.errorAlert}>
      <AlertTitle>
        {errorMsg
          ? 'There has been an error with the request'
          : 'Please check your email and password'}
      </AlertTitle>
      {errorMsg || failedLoginMessage}
    </Alert>
  );

  const loginForm = () => (
    // Adding noValidate means that we won't use the browser's default validation, but rather our own validation style
    <Box component="form" onSubmit={handleSubmit} noValidate>
      {failedSignIn && renderErrorAlert()}
      <FormGroup sx={styles.formGroup}>
        {emailField()}
        {passwordField()}
        <Link
          to={paths.REQUEST_PASSWORD_RESET}
          state={{ email: loginValues.email }}
          style={styles.passwordLink}>
          <Typography variant="bodyLink">Forgotten your password?</Typography>
        </Link>
        <Button
          type="submit"
          color="secondary"
          label="Sign In"
          style={styles.submitBtn}
          loading={signInLoading}
        />
      </FormGroup>
    </Box>
  );

  return loginForm();
};

const styles = {
  formGroup: { width: { lg: '60%', xs: '100%' } },
  passwordLink: { marginBottom: 24, marginTop: 8 },
  submitBtn: { width: 'fit-content' },
  errorAlert: { mb: 5 }
};

export default LoginForm;
