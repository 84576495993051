/* eslint-disable react/prop-types */
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import ErrorPageContent from '../components/ErrorPageContent/ErrorPageContent';

// Due to the ErrorPageContent component being used within the error boundary, we are unable to make use
// of the useAuth/Navigation hooks inside that component, due to the nature of the boundary having to be wrapped
// outside of the routes/contexts.
// Therefore, the navigate function is initialised and isAuthenticated bool stored here, and are passed in as props.

export default function ErrorPage({ content }) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    <Box mt={isAuthenticated ? 0 : 24} data-testid="error-page">
      <ErrorPageContent name={content} isAuthenticated={isAuthenticated} navigate={navigate} />
    </Box>
  );
}
