/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import { Button } from '@rocksteady-music-school/rms-ui';

export default function BandLessonsCard({ img, title, content, buttonProps }) {
  const card = () => (
    <Box
      style={{ ...styles.card, backgroundImage: `url(${img})` }}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height={460}
      paddingX={24}
      paddingY={32}>
      <Box>
        <Typography variant="h3" color="whiteWedding.main" mb={8}>
          {title}
        </Typography>
        <Typography variant="bodyBold" color="whiteWedding.main">
          {content}
        </Typography>
      </Box>

      <Button
        {...buttonProps}
        outlined
        style={{
          width: '100%',
          background: 'white',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
          '&:hover': { background: 'white' }
        }}
      />
    </Box>
  );

  return card();
}

const styles = {
  card: {
    backgroundSize: 'cover',
    borderRadius: '10px'
  }
};
