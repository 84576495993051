/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { Badge, Box, Button, Grid, Menu, Typography } from '@mui/material';
import { StaticDatePicker, PickersDay } from '@mui/x-date-pickers';
import { noop, startCase } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DATE_DISPLAY_FORMAT_CODE = 'ddd Do MMM'; // e.g. Mon 9th Dec

export default function FGFNavigator({
  children,
  dates,
  activeDate,
  onPrevSelect,
  onNextSelect,
  onDateSelect,
  tabEntries,
  onTabSelect
}) {
  const onLatest = activeDate === dates[0];
  const onEarliest = activeDate === dates[dates.length - 1];

  const activeDateIndex = dates.indexOf(activeDate);
  const prevDateFormatted =
    !onEarliest && dates[activeDateIndex + 1]?.format(DATE_DISPLAY_FORMAT_CODE);
  const activeDateFormatted = activeDate && activeDate.format(DATE_DISPLAY_FORMAT_CODE);
  const nextDateFormatted =
    !onLatest && dates[activeDateIndex - 1]?.format(DATE_DISPLAY_FORMAT_CODE);

  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const calendarOpen = Boolean(calendarAnchorEl);
  const handleCalendarButtonClick = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };
  const handleCalendarClose = () => {
    setCalendarAnchorEl(null);
  };

  useEffect(() => {
    if (calendarOpen) handleCalendarClose();
  }, [activeDate]);

  // Wraps the MUI PickersDay component to enable the display of red badges
  // for where content is applicable/exists for the given date.
  const renderCalendarDay = (day, _value, DayComponentProps) => {
    const requiresBadge =
      !DayComponentProps.outsideCurrentMonth && dates.findIndex((d) => !d.diff(day)) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        variant="dot"
        color={requiresBadge ? 'secondary' : undefined}>
        <PickersDay {...DayComponentProps} disabled={!requiresBadge} />
      </Badge>
    );
  };

  const prevButton = () => (
    <Box
      data-testid="prev"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={onEarliest ? styles.disabled : styles.prevNextButton}
      onClick={onEarliest ? undefined : onPrevSelect}>
      <NavigateBeforeIcon />
      {prevDateFormatted}
    </Box>
  );

  const nextButton = () => (
    <Box
      data-testid="next"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={onLatest ? styles.disabled : styles.prevNextButton}
      onClick={onLatest ? undefined : onNextSelect}>
      {nextDateFormatted}
      <NavigateNextIcon />
    </Box>
  );

  const dateNavigationActions = () => (
    <Grid
      item
      md={12}
      lg={6}
      marginBottom={{ xs: 8, lg: 0 }}
      display="flex"
      justifyContent="start"
      alignItems="center">
      {Boolean(dates.length) && (
        <Box display="flex" alignItems="center" justifyContent="center">
          {prevButton()}
          {calendar()}
          {nextButton()}
        </Box>
      )}
    </Grid>
  );

  const calendar = () => (
    <>
      <Button
        color="primary"
        onClick={handleCalendarButtonClick}
        endIcon={<ArrowDropDownIcon />}
        sx={styles.calendarButton}>
        {activeDateFormatted}
      </Button>
      <Menu anchorEl={calendarAnchorEl} open={calendarOpen} onClose={handleCalendarClose}>
        <StaticDatePicker
          data-testid="calendar-button"
          displayStaticWrapperAs="desktop"
          views={['day']}
          value={activeDate}
          onChange={onDateSelect}
          minDate={dates[dates.length - 1]}
          maxDate={dates[0]}
          renderDay={renderCalendarDay}
          disableHighlightToday
          renderInput={noop}
        />
      </Menu>
    </>
  );

  const tabs = () => (
    <Grid item md={12} lg={6} display="flex" justifyContent="end">
      {tabEntries.map(({ name, title, color, plural }) => (
        <Box
          data-testid={`${name}-tab`}
          key={uuidv4()}
          backgroundColor={color}
          textAlign="center"
          sx={styles.tab}
          onClick={() => onTabSelect(name)}>
          <Typography variant="h4" sx={{ lineHeight: 1 }}>
            {title}
          </Typography>
          <Typography variant="body" sx={{ lineHeight: 0.5, fontStyle: 'italic' }}>
            {startCase(plural)}
          </Typography>
        </Box>
      ))}
    </Grid>
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Grid container columnSpacing={2}>
          {dateNavigationActions()}
          {tabs()}
        </Grid>
      </Box>
      {children}
    </Box>
  );
}

const styles = {
  calendarButton: {
    margin: '0 16px',
    minWidth: 180,
    fontSize: 'larger',
    letterSpacing: '-0.02em',
    fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
    textTransform: 'uppercase'
  },
  prevNextButton: {
    minWidth: 120,
    letterSpacing: '-0.02em',
    fontSize: 'larger',
    fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disabled: {
    opacity: '50%'
  },
  nextButton: {
    backgroundColor: 'transparent'
  },
  tab: {
    padding: '16px 32px 8px 32px',
    borderRadius: '25px 25px 0px 0px',
    cursor: 'pointer'
  }
};
