/* eslint-disable class-methods-use-this */
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class SchoolsUserEventLogAPI {
  createLog = (params) =>
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools_user_event_logs.json`,
      config: { data: params }
    });
}

export default new SchoolsUserEventLogAPI();
