/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Alert, AlertTitle, Stack, Tooltip, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';
import StringUtils from '../../utils/StringUtils';
import userRoles from '../../consts/userRoles';
import UserAPI from '../../api/UserAPI';
import PermissionChangeModal from './PermissionChangeModal';
import PermissionsCard from '../PermissionsCard/PermissionsCard';

export default function PermissionChangeSection({ roleName }) {
  const { enqueueSnackbar } = useSnackbar();
  const userRoleAbilities = userRoles[roleName.toUpperCase()].roleAbilities;

  const [permissionChangeModalOpen, setPermissionChangeModalOpen] = useState(false);
  const [roleChangeRequests, setRoleChangeRequests] = useState([]);
  const [loadingRoleChangeRequests, setLoadingRoleChangeRequests] = useState(false);
  const [errors, setErrors] = useState(null);

  const closePermissionChangeModal = () => setPermissionChangeModalOpen(false);

  useEffect(() => {
    if (!!errors && permissionChangeModalOpen) {
      closePermissionChangeModal();
    }
  }, [errors]);

  const handleErrorResponse = (error) => {
    if (error.response?.data?.errors) {
      setErrors(error.response.data.errors);
    } else {
      setErrors([error.message]);
    }
  };

  const fetchAndSetPendingRoleChangeRequests = () => {
    setLoadingRoleChangeRequests(true);
    UserAPI.myRoleChangeRequests()
      .then(({ data }) => setRoleChangeRequests(data.data))
      .catch((err) => handleErrorResponse(err))
      .finally(() => setLoadingRoleChangeRequests(false));
  };

  const cancelRoleChangeRequest = (pendingRequestId) => {
    setLoadingRoleChangeRequests(true);
    UserAPI.cancelRoleChangeRequest(pendingRequestId)
      .then(() => {
        closePermissionChangeModal();
        enqueueSnackbar('Done! The request to change permission has been discarded.', {
          variant: 'success'
        });
      })
      .catch((err) => handleErrorResponse(err))
      .finally(() => setLoadingRoleChangeRequests(false));
  };

  const sendRoleChangeRequest = (requestedRole) => {
    const data = {
      data: { role_requested: requestedRole }
    };
    setLoadingRoleChangeRequests(true);
    UserAPI.requestRoleChange(data)
      .then(() => {
        closePermissionChangeModal();
        enqueueSnackbar(
          'We have received your request and are working on it. If more details are needed, our schools-support team will be in touch.',
          { variant: 'success', autoHideDuration: 8000 }
        );
      })
      .catch((err) => handleErrorResponse(err))
      .finally(() => setLoadingRoleChangeRequests(false));
  };

  const onOpenPermissionChangeModal = () => {
    setPermissionChangeModalOpen(true);
    fetchAndSetPendingRoleChangeRequests();
  };

  const alertMsgs = () => (
    <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
      {errors.map((er) => (
        <li key={uuidv4()}>{er}</li>
      ))}
    </ul>
  );

  const renderErrorAlert = () => (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {alertMsgs()}
    </Alert>
  );

  const renderToolTip = () => (
    <Tooltip
      arrow
      title={
        <PermissionsCard tooltip roleName={roleName} abilities={userRoleAbilities} isCurrent />
      }
      placement="top">
      <InfoIcon sx={{ color: 'paintItBlack.40' }} />
    </Tooltip>
  );

  return (
    <>
      <Stack spacing={8}>
        <Typography variant="bodyBold" component="p">
          Current Permission
        </Typography>
        {!!errors && renderErrorAlert()}
        <Stack direction="row" alignItems="center" spacing={16}>
          <Typography variant="body" component="p" color="fadeToGrey.main">
            {`${StringUtils.upperCaseFirstLetter(roleName)} access`}
          </Typography>
          {renderToolTip()}
          <Typography variant="bodyLink" onClick={onOpenPermissionChangeModal} alignSelf="center">
            Change your permissions
          </Typography>
        </Stack>
      </Stack>
      {permissionChangeModalOpen && (
        <PermissionChangeModal
          currentRole={roleName}
          closeModal={closePermissionChangeModal}
          onNewRequest={sendRoleChangeRequest}
          onCancelRequest={cancelRoleChangeRequest}
          pendingRequest={roleChangeRequests[roleChangeRequests.length - 1]}
          loading={loadingRoleChangeRequests}
        />
      )}
    </>
  );
}
