/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export function SkeletonTable({ size }) {
  const largeTable = size === 'lg';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const cell = (children) => (
    <TableCell key={uuidv4()} align="center" sx={{ borderColor: 'transparent' }}>
      {children}
    </TableCell>
  );

  const blockCell = () => cell(<Skeleton variant="rectangular" height="100%" width="100%" />);

  const linesCell = () => cell([...Array(3).keys()].map(() => <Skeleton key={uuidv4()} />));

  const tableHeader = () => (
    <TableRow sx={{ height: '20%' }}>
      {cell()}
      {[...Array(mobileView ? 3 : 7).keys()].map(() => blockCell())}
    </TableRow>
  );
  const tableRow = () => (
    <TableRow key={uuidv4()}>
      {blockCell()}
      {[...Array(mobileView ? 3 : 7).keys()].map(() => linesCell())}
    </TableRow>
  );

  const tableFrame = () => (
    <TableContainer sx={{ height: '100%' }}>
      <Table sx={{ height: '100%' }}>
        <TableBody>
          {tableHeader()}
          {[...Array(largeTable ? 4 : 3).keys()].map(() => tableRow())}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return tableFrame();
}
