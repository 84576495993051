/* eslint-disable no-use-before-define */

import { Typography, Box, Grid } from '@mui/material';
import fgfHeader from '../../assets/images/fgf_header.png';

export default function FGFHeader() {
  const header = () => (
    <Box sx={styles.header}>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Typography variant="h1" color="whiteWedding.main" mb={25}>
            Feel Good Friday
          </Typography>
          <Typography variant="h4" color="whiteWedding.main">
            Inspire your pupils with engaging and exciting 30-minute streamed, whole-class music
            lessons. It&apos;s free to access, and all videos from this academic year are available
            to stream on demand, at any time.
          </Typography>
        </Grid>
        <Grid item xs={0} lg={4} />
      </Grid>
    </Box>
  );

  return header();
}

const styles = {
  header: {
    backgroundImage: `url(${fgfHeader})`,
    backgroundSize: 'cover',
    marginBottom: '28px',
    borderRadius: '25px',
    padding: '56px 24px'
  }
};
