/* eslint-disable no-use-before-define */
import { Alert, Box, FormGroup, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';

import formBg from '../../assets/images/band_lessons_form.png';
import response from '../../consts/actions';
import contactDetails from '../../consts/contactDetails';
import CallbackAPI from '../../api/CallbackAPI';
import StringUtils from '../../utils/StringUtils';

const eightAM = dayjs().set('hour', 8).startOf('hour');
const fourPM = dayjs().set('hour', 16).startOf('hour');

// Preload the form background
const img = new Image();
img.src = formBg;

export default function BandLessonsForm() {
  const { enqueueSnackbar } = useSnackbar();

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [formState, setFormState] = useState(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);

  const failedSubmit = formState === response.ERROR;
  const formSubmitLoading = formState === response.LOADING;

  const initialFormValues = {
    tel: '',
    dateTime: null,
    message: ''
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const { tel, dateTime, message } = formValues;

  useEffect(() => {
    if (showValidationErrors) {
      if (formValid()) {
        setShowValidationErrors(false);
        setFormState(response.LOADING);
        requestCallback();
      }
    }
  }, [showValidationErrors]);

  const dateTimeIsValid = () => {
    // If the user selects 11AM (a valid time) and then clicks on PM, turning it into an invalid time
    // the DateTimePicker onError prop gets triggered, flagging this as an error, which we store in the dateTimeError state
    if (dateTimeError) return false;

    // If the user presses Submit and we don't have a dateTime selected, it's invalid
    if (showValidationErrors && !dateTime) return false;

    // Otherwise, if the user hasn't yet pressed on Submit, double-check when a date is selected that it is in the future
    // The user shouldn't be able to select past dates, but they can select today and a time that is earlier than the current time.
    // This is an MUI bug which has been fixed but only released in an alpha version https://github.com/mui/mui-x/pull/6226
    if (!dateTime) return true;
    return dateTime.isAfter(dayjs());
  };

  const showTelephoneErrorMsg = showValidationErrors && !StringUtils.isTelephoneIsh(tel);

  const formValid = () => dateTimeIsValid() && StringUtils.isTelephoneIsh(tel);

  const disableWeekends = (date) => [0, 6].includes(date.get('day'));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowValidationErrors(false);
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateTimeChange = (newValue) => {
    setShowValidationErrors(false);
    setDateTimeError(false);
    setFormValues({ ...formValues, dateTime: newValue });
  };

  const telNumberField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      value={tel}
      id="tel"
      label="Preferred Telephone Number"
      name="tel"
      autoComplete="tel"
      onChange={handleInputChange}
      error={showTelephoneErrorMsg}
      helperText={showTelephoneErrorMsg ? 'Please enter a valid telephone number' : ''}
      InputProps={{
        style: { backgroundColor: 'white' }
      }}
      sx={{
        marginBottom: 19,
        width: { xs: '100%', sm: 300 }
      }}
    />
  );

  const dateTimeField = () => (
    <DateTimePicker
      open={openDatePicker}
      onOpen={() => setOpenDatePicker(true)}
      onClose={() => setOpenDatePicker(false)}
      ampm
      label="Preferred Date &amp; Time for Call Back"
      disablePast
      minutesStep={5}
      minTime={eightAM}
      maxTime={fourPM}
      onError={(reason) => {
        if (reason) setDateTimeError(true);
      }}
      shouldDisableDate={disableWeekends}
      value={dateTime}
      onChange={handleDateTimeChange}
      InputProps={{
        style: { backgroundColor: 'white' }
      }}
      showToolbar={false}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          name="dateTime"
          error={!dateTimeIsValid()}
          helperText={
            !dateTimeIsValid() && 'Please choose a future date between Monday-Friday 8AM-4PM'
          }
          sx={{
            marginBottom: 3,
            width: { xs: '100%', sm: 300 }
          }}
          // Disable the default editable behaviour of the text field
          // and instead make it so that on click it opens the picker
          onClick={() => setOpenDatePicker(true)}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
        />
      )}
    />
  );

  const messageField = () => (
    <TextField
      margin="normal"
      fullWidth
      id="message"
      label="My Enquiry"
      name="message"
      placeholder="Please contact me about in-school lessons"
      value={message}
      onChange={handleInputChange}
      InputProps={{
        style: { backgroundColor: 'white' }
      }}
      sx={{
        marginBottom: 19,
        width: { xs: '100%', sm: 493 }
      }}
    />
  );

  const requestCallback = () => {
    CallbackAPI.requestCallback({
      data: {
        telephone: tel,
        date_time: dateTime,
        message
      }
    })
      .then(() => {
        setFormState(response.SUCCESS);
        enqueueSnackbar(
          'Thank you for submitting the callback request. We will be in touch soon.',
          { variant: 'success' }
        );
        setFormValues(initialFormValues);
      })
      .catch(() => setFormState(response.ERROR));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Once the state updates to show validation errors,
    // a useEffect will get triggered to validate the form and submit it if it's valid.
    // This ensures we only perform the form validation after this piece of state gets updated
    setShowValidationErrors(true);
  };

  const submitButton = () => (
    <Button
      type="submit"
      label="Submit Request"
      loading={formSubmitLoading}
      style={{ width: 'fit-content' }}
    />
  );

  const renderErrorAlert = () => (
    <Alert
      severity="error"
      sx={{ mb: 16, width: { xs: '100%', sm: 493 }, boxSizing: 'border-box' }}>
      There was a problem with sending the callback request. Please try again or contact the Schools
      Hotline if the issue continues.
    </Alert>
  );

  const form = () => (
    <Box
      sx={styles.form}
      paddingX={{ xs: 15, sm: 40 }}
      paddingY={{ xs: 30, sm: 40 }}
      marginTop={40}>
      <Box maxWidth={595}>
        <Typography variant="h2" color="paintItBlack.main" mb={16}>
          Request a call back
        </Typography>
        {failedSubmit && renderErrorAlert()}
        <Typography variant="body" component="p" mb={3}>
          <Typography variant="body" color="rubyTuesday.110">
            *
          </Typography>{' '}
          Mandatory field
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <FormGroup>
            {telNumberField()}
            {dateTimeField()}
            {messageField()}
            {submitButton()}
          </FormGroup>
        </Box>
        <Typography variant="bodyBold" component="p" mt={24}>
          Alternatively call{' '}
          <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
            <Typography variant="bodyLinkBold">{contactDetails.SCHOOLS_TEL}</Typography>
          </a>{' '}
          or email{' '}
          <a href={`mailto:${contactDetails.TELL_ME_MORE_EMAIL}`}>
            <Typography variant="bodyLinkBold">{contactDetails.TELL_ME_MORE_EMAIL}</Typography>
          </a>
        </Typography>
      </Box>
    </Box>
  );

  return form();
}

const styles = {
  form: {
    backgroundImage: `url(${formBg})`,
    backgroundSize: 'cover',
    borderRadius: '10px'
  }
};
