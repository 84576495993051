export default class FileUtils {
  static bytesToMb = (bytes) => {
    const mbLong = bytes / 1048576;
    return Math.ceil(mbLong * 1e1) / 1e1;
  };

  static fileSizeIsValid = (file, maxAllowedSize) => this.bytesToMb(file.size) <= maxAllowedSize;

  static imageDimensions = (fr) => {
    return new Promise((resolve, reject) => {
      const reader = fr;
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (ev) => {
          const { height } = ev.target;
          const { width } = ev.target;
          resolve({ width, height });
        };
      };
      reader.onerror = reject;
    });
  };
}
