import { createTheme } from '@mui/material';
// The theme can be accessed in any component as follows:
// const theme = useTheme();
// And then to access a certain colour: theme.palette.primary.main
// Or, for colours, if you're using an MUI component you can use directly 'rubyTuesday.main' without useTheme

const paletteTheme = createTheme({
  palette: {
    // Because "primary" and "secondary" are MUI colors, they need to be overwritten so that we don't
    // use the default primary and secondary MUI colors. This is why they also appear here and not just under
    // the name of blueSuedeShoes and mrBlueSky
    primary: {
      main: '#0072CE'
    },
    secondary: {
      main: '#00E7EF'
    },
    error: {
      main: '#E32B2B'
    },
    paintItBlack: {
      5: '#F5F5F4',
      10: '#E9E9EA',
      40: '#a9aaad',
      main: '#292B32'
    },
    whiteWedding: {
      main: '#ffffff'
    },
    blueSuedeShoes: {
      5: '#F2F8FD',
      10: '#E5F0FA',
      20: '#CCE2F5',
      main: '#0072CE',
      130: '#004F90',
      140: '#00447b'
    },
    mrBlueSky: {
      main: '#00E7EF',
      150: '#007377'
    },
    purpleRain: {
      main: '#DA38A3',
      120: '#AE2C82'
    },
    prettyInPink: {
      10: '#FBE7f2',
      20: '#F7D0E6',
      main: '#DA1884'
    },
    rubyTuesday: {
      10: '#FEEAEA',
      20: '#FED5D5',
      main: '#FD3030',
      110: '#E32B2B',
      160: '#651313'
    },
    greenOnions: {
      10: '#E5FDF7',
      20: '#CCFCEF',
      60: '#66F6D0',
      main: '#00F0B2',
      140: '#00906A',
      150: '#007859',
      170: '#004835'
    },
    yellowSubmarine: {
      10: '#FEFDF0',
      20: '#FEFBE2',
      40: '#FDF8C6',
      main: '#FAEF71',
      140: '#AFA74F',
      150: '#7D7738',
      160: '#645F2D'
    },
    mellowYellow: {
      main: '#F3D69D',
      140: '#91805E',
      160: '#61553E'
    },
    // NON-BRAND COLOURS
    fadeToGrey: {
      main: '#4A5568'
    }
  }
});

const getBtnColor = (color) => {
  switch (color) {
    case 'primary':
      return paletteTheme.palette.blueSuedeShoes.main;
    case 'secondary':
      return paletteTheme.palette.mrBlueSky.main;
    case 'rubyTuesday':
      return paletteTheme.palette.rubyTuesday.main;
    default:
      return 'transparent';
  }
};

const calcBtnPadding = (ownerState) => {
  // We want the vertical padding to be 12, however, when we have an icon
  // the content height will increase due to the icon, so we reduce the padding accordingly
  return ownerState.startIcon ? '10.2px 30px' : '12px 30px';
};

const MuiTheme = createTheme(paletteTheme, {
  spacing: (factor) => 1 * factor,
  typography: {
    h1: {
      [paletteTheme.breakpoints.up('xs')]: {
        fontSize: 46,
        lineHeight: '50px'
      },
      [paletteTheme.breakpoints.up('sm')]: {
        fontSize: 48,
        lineHeight: '52px'
      },
      [paletteTheme.breakpoints.up('md')]: {
        fontSize: 52,
        lineHeight: '56px'
      },
      letterSpacing: '-0.02em',
      fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
      color: paletteTheme.palette.paintItBlack.main,
      textTransform: 'uppercase'
    },
    h2: {
      fontSize: 38,
      lineHeight: '42px',
      letterSpacing: '-0.02em',
      fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
      color: paletteTheme.palette.paintItBlack.main,
      textTransform: 'uppercase'
    },
    h3: {
      fontSize: 30,
      lineHeight: '34px',
      letterSpacing: '-0.02em',
      fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
      color: paletteTheme.palette.paintItBlack.main,
      textTransform: 'uppercase'
    },
    h4: {
      fontSize: 22,
      lineHeight: '26px',
      letterSpacing: '-0.02em',
      fontFamily: 'Trade Gothic LT W01 Bd Cn No-2',
      color: paletteTheme.palette.paintItBlack.main,
      textTransform: 'uppercase'
    },
    h5: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0,
      fontFamily: "'Roboto', sans-serif",
      color: paletteTheme.palette.paintItBlack.main
    },
    body: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0,
      fontFamily: "'Roboto', sans-serif",
      color: paletteTheme.palette.paintItBlack.main
    },
    bodySecondary: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0,
      fontFamily: 'Roboto',
      color: paletteTheme.palette.fadeToGrey.main
    },
    bodyBold: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0,
      fontFamily: "'Roboto', sans-serif",
      color: paletteTheme.palette.paintItBlack.main
    },
    bodyLink: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: "'Roboto', sans-serif",
      letterSpacing: 0,
      color: paletteTheme.palette.blueSuedeShoes.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:active': {
        color: paletteTheme.palette.blueSuedeShoes[140]
      }
    },
    bodyLinkBold: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: "'Roboto', sans-serif",
      letterSpacing: 0,
      color: paletteTheme.palette.blueSuedeShoes.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:active': {
        color: paletteTheme.palette.blueSuedeShoes[140]
      }
    },
    bodyLinkDisabled: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: "'Roboto', sans-serif",
      letterSpacing: 0,
      color: paletteTheme.palette.paintItBlack[40],
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette[ownerState.color].main,
          color:
            ownerState.color === 'secondary'
              ? theme.palette.paintItBlack.main
              : theme.palette.whiteWedding.main,
          fontFamily: "'Roboto', sans-serif",
          fontWeight: '700',
          textTransform: 'inherit',
          border: 0,
          borderRadius: '5px',
          display: 'inline-flex',
          lineHeight: '16.41px',
          fontSize: 14,
          letterSpacing: 0,
          padding: calcBtnPadding(ownerState),
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: getBtnColor(ownerState.color),
            border: 1,
            borderColor: getBtnColor(ownerState.color),
            borderStyle: 'solid'
          }),
          '&.Mui-disabled': {
            backgroundColor: paletteTheme.palette.paintItBlack[40],
            color: 'white',
            border: 0
          },
          '&:hover': {
            backgroundColor:
              ownerState.variant === 'outlined'
                ? 'transparent'
                : theme.palette[ownerState.color].main
          }
        })
      },
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true
      }
    },
    MuiPickersToolbarButton: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'transparent',
          '&:hover': {
            padding: 5,
            '& span': {
              color: 'white'
            }
          }
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: () => ({
          fontSize: '14px',
          lineHeight: '20px',
          paddingTop: '14px',
          paddingBottom: '14px',
          height: '20px',
          letterSpacing: 0
        }),
        root: () => ({
          lineHeight: '20px'
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          '& .MuiOutlinedInput-root': {
            padding: 0,
            '& .MuiAutocomplete-input': {
              padding: '14px 0px 14px 14px'
            }
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          top: '-2px',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0
        }),
        shrink: () => ({
          top: '-1px',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0,
          transform: 'translate(14px, -9px) scale(0.9)'
        }),
        asterisk: ({ theme }) => ({
          color: theme.palette.rubyTuesday[110]
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: () => ({
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0,
          maxWidth: 'none'
        }),
        popper: () => ({
          opacity: 0.99
        })
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '10px'
        },
        message: ({ theme }) => ({
          ...theme.typography.body,
          color: 'inherit'
        }),
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.rubyTuesday[10],
          color: theme.palette.rubyTuesday[160],
          '& .MuiAlert-icon': {
            color: theme.palette.rubyTuesday.main
          }
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: theme.palette.yellowSubmarine[20],
          color: theme.palette.yellowSubmarine[160],
          '& .MuiAlert-icon': {
            color: theme.palette.yellowSubmarine[150]
          }
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: theme.palette.blueSuedeShoes[10],
          color: theme.palette.blueSuedeShoes[130],
          '& .MuiAlert-icon': {
            color: theme.palette.blueSuedeShoes.main
          }
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.greenOnions[20],
          color: theme.palette.greenOnions[170],
          '& .MuiAlert-icon': {
            color: theme.palette.greenOnions[140]
          }
        })
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.bodyBold,
          lineHeight: '24px', // Necessary for the alignment with the icon
          color: 'inherit'
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 80,
          height: 48,
          padding: 8
        },
        switchBase: ({ theme }) => ({
          padding: 11,
          '&.Mui-checked': {
            transform: 'translateX(32px)',
            '&:hover': {
              // rgb(0,114,206) is the RGB correspondent of our primary colour #0072ce
              backgroundColor: 'rgba(0,114,206,0.08)'
            },
            '& + .MuiSwitch-track': {
              background: theme.palette.primary.main,
              '&:before': {
                opacity: 1
              },
              '&:after': {
                opacity: 0
              }
            }
          }
        }),
        thumb: ({ theme }) => ({
          width: 26,
          height: 26,
          backgroundColor: theme.palette.whiteWedding.main,
          '&.Mui-checked': {
            backgroundColor: theme.palette.whiteWedding.main
          }
        }),
        track: ({ theme }) => ({
          background: theme.palette.paintItBlack[40],
          opacity: '1 !important',
          borderRadius: 20,
          position: 'relative',
          '&:before, &:after': {
            display: 'inline-block',
            position: 'absolute',
            top: '50%',
            width: '50%',
            transform: 'translateY(-50%)',
            color: theme.palette.whiteWedding.main,
            textAlign: 'center'
          },
          '&:before': {
            content: '"on"',
            left: 4,
            opacity: 0
          },
          '&:after': {
            content: '"off"',
            right: 4
          }
        })
      }
    },
    MuiTable: {
      styleOverrides: {
        root: () => ({
          '& colgroup': {
            '& col': {
              '&:not(:last-child)': {
                borderRight: '1px solid #b9b9b9'
              }
            }
          }
        })
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: () => ({
          borderBottomWidth: 0,
          wordWrap: 'break-word'
        }),
        // Styles to make the table head sticky
        // Mainly useful on smaller screens or for tables with many rows
        head: () => ({
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          // The line below is needed to ensure the table head borders display in Firefox
          backgroundClip: 'padding-box',
          '&:after, :before': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: '100%'
          },
          '&:before': {
            top: '-1px',
            borderTop: '1px solid #b9b9b9',
            borderBottom: '0px solid'
          },
          '&:after': {
            borderBottom: '2px solid #b9b9b9',
            bottom: '-2px'
          }
        })
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: () => ({
          '&:not(:last-child)': {
            borderBottom: '1px solid #b9b9b9'
          }
        }),
        head: () => ({
          height: '1%', // On Firefox this seems to be the only thing that ensures the table head only takes up the space it needs when there are no other rows
          borderBottom: '1px solid #b9b9b9'
        })
      }
    }
  }
});

export default MuiTheme;
