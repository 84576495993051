/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';

export const UserContext = createContext();
export const useUserData = () => useContext(UserContext);

export function UserProviderContext(props) {
  const { properties, children } = props;
  return <UserContext.Provider value={{ ...properties }}>{children}</UserContext.Provider>;
}
