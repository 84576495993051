export const logoHeight = 83;
export const spaceBetweenLogoAndMenu = 17;
export const containerMarginX = 33;
export const xsContainerMarginX = 8;
export const oneColWidth = 100 / 12;
export const twoColWidth = 200 / 12;
export const threeColWidth = 300 / 12;
export const fourColWidth = 400 / 12;
export const fiveColWidth = 500 / 12;
export const sixColWidth = 600 / 12;
export const sevenColWidth = 700 / 12;
export const eightColWidth = 800 / 12;
export const nineColWidth = 900 / 12;
export const tenColWidth = 1000 / 12;
export const elevenColWidth = 1100 / 12;
export const drawerWidth = 233;
