import React, { useState } from 'react';
import { Alert, AlertTitle, Box, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';
import TimetableAPI from '../../api/TimetableAPI';
import response from '../../consts/actions';

export default function emailTTSection({ timetableId, timetableDateParams, setEmailModalVisible }) {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [sendEmailRequestStatus, setSendEmailRequestStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const emailValid = () => StringUtils.isEmailIsh(email);

  const flagEmailInvalid = () => showValidationErrors && !emailValid();

  const handleSendingEmail = () => {
    setShowValidationErrors(true);
    if (emailValid()) {
      setSendEmailRequestStatus(response.LOADING);
      TimetableAPI.emailTTPdf(timetableId, {
        data: { ...timetableDateParams, pdf_on_demand_email_address: email }
      })
        .then(() => {
          setSendEmailRequestStatus(response.SUCCESS);
          setEmailModalVisible(false);
          enqueueSnackbar('The timetable has been successfully emailed.', {
            variant: 'success'
          });
        })
        .catch((e) => {
          const error = e.response?.data?.errors || e.message;

          setSendEmailRequestStatus(response.ERROR);
          setErrorMsg(error);
        });
    }
  };

  const buttonProps = {
    label: 'Send a Copy',
    onClick: handleSendingEmail,
    iconName: 'mail',
    disabled: !email,
    loading: sendEmailRequestStatus === response.LOADING,
    sx: { width: '100%' }
  };

  const alertMsg = () => {
    if (Array.isArray(errorMsg)) {
      return (
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 12 }}>
          {errorMsg.map((er) => (
            <li key={uuidv4()}>{er}</li>
          ))}
        </ul>
      );
    }
    return errorMsg;
  };

  const renderErrorAlert = () => (
    <Alert severity="error" sx={{ mb: 16 }}>
      {Array.isArray(errorMsg) && <AlertTitle>There were some errors with the request</AlertTitle>}
      {alertMsg()}
    </Alert>
  );

  const handleInputChange = (event) => {
    setShowValidationErrors(false);
    setEmail(event?.target?.value);
  };

  const emailForm = () => (
    <Box component="form" onSubmit={handleSendingEmail} noValidate>
      <FormGroup>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={12}
          mb={8}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            value={email}
            error={flagEmailInvalid()}
            helperText={flagEmailInvalid() ? 'Please enter a valid email address' : ''}
            onChange={handleInputChange}
            sx={{ maxWidth: 340, mb: 0 }}
          />
          <Box height={48} display="flex" alignItems="center" width={{ xs: '100%', sm: 'initial' }}>
            <Button {...buttonProps} />
          </Box>
        </Stack>
      </FormGroup>
    </Box>
  );

  const modalContent = () => (
    <>
      {sendEmailRequestStatus === response.ERROR && renderErrorAlert()}

      <Typography variant="body" component="p" mb={24}>
        Send a copy of the timetable to a colleague, on demand, right now.
      </Typography>
      {emailForm()}
    </>
  );

  const section = () => ({
    title: 'Email Timetable',
    content: modalContent()
  });

  return section();
}
