/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Box } from '@mui/material';
import React from 'react';
import BandsTable from '../BandsTable/BandsTable';
import { SkeletonTable } from '../SkeletonTable/SkeletonTable';

export default function BandsContainer({ bandsDataLoading, bandsData }) {
  const bands = bandsData?.bands;
  const ttHasBands = Object.keys(bands || {}).length;

  return (
    <Box sx={{ bgcolor: 'whiteWedding.main' }}>
      <Box
        sx={{
          overflow: 'hidden',
          height: ttHasBands ? 580 : 366,
          borderRadius: '2px',
          outline: '1px solid #b9b9b9'
        }}>
        {bandsDataLoading ? <SkeletonTable size="lg" /> : <BandsTable bandsData={bandsData} />}
      </Box>
    </Box>
  );
}
