const ROCKSTEADY_AGE_GROUPS = Object.freeze({
  miniRocker: {
    years: 'Years R-2',
    color: '#FDF8C6',
    plural: 'miniRockers'
  },
  rockHero: {
    years: 'Years 3-4',
    color: '#99F9E0',
    plural: 'rockHeroes'
  },
  rockIcon: {
    years: 'Years 5-6',
    color: '#4CEEF3',
    plural: 'rockIcons'
  }
});

export default ROCKSTEADY_AGE_GROUPS;
