/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export function AuthProviderContext(props) {
  const { properties, children } = props;
  return <AuthContext.Provider value={{ ...properties }}>{children}</AuthContext.Provider>;
}
