/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ContentMissingPlaceholder } from '@rocksteady-music-school/rms-ui';
import DateUtils from '../../utils/DateUtils';
import StringUtils from '../../utils/StringUtils';
import useWindowSize from '../../hooks/useWindowSize';

function Timetable({ timetableData, bandsData, dateRange }) {
  const { core, rehearsals, allDayEvents, allDates, rehearsalTimes } = timetableData;
  const { bandIdLetterMap, bands } = bandsData;

  const theme = useTheme();
  const windowSize = useWindowSize(1000);
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const targetRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);

  const calendarIcon = (
    <FontAwesomeIcon
      key="fa-calendar-alt"
      className="fa-fw"
      icon={faCalendarAlt}
      style={{ overflow: 'visible', fontSize: 36 }}
      color={theme.palette.paintItBlack[40]}
    />
  );

  useLayoutEffect(() => {
    if (targetRef.current) {
      setTableWidth(targetRef.current.offsetWidth);
    }
  }, [windowSize]);

  const moreThanSevenDates = allDates.length > 7;

  const colSplit = () => {
    // Display 2.5 columns if it's a small device
    if (isSmall) return 3.5;

    // Otherwise display 7 columns or 7.5 if there are more than seven dates
    return moreThanSevenDates ? 8.5 : 8;
  };

  const colWidth = tableWidth / colSplit();

  const cellText = (children, warning) => (
    <Typography
      variant={warning ? 'bodyBold' : 'body'}
      color={warning ? 'rubyTuesday.main' : 'paintItBlack.main'}>
      {children}
    </Typography>
  );

  const secondaryCellText = (children, warning) => (
    <Typography
      variant={warning ? 'bodyBold' : 'body'}
      color={warning ? 'rubyTuesday.main' : 'paintItBlack.40'}>
      {children}
    </Typography>
  );

  const getRehearsal = (dateId, timeId) =>
    rehearsals.find((rehearsal) => rehearsal.dateId === dateId && rehearsal.timeId === timeId);

  const duplicateBandByDate = (dateId, bandId, rehearsalId) =>
    rehearsals.find(
      (rehearsal) =>
        rehearsal.bandId &&
        rehearsal.dateId === dateId &&
        rehearsal.bandId === bandId &&
        rehearsal.id !== rehearsalId
    );

  const bandById = (id) => {
    const key = bandIdLetterMap[id];
    return bands[key];
  };

  const eventById = (id) => allDayEvents.find((event) => event.id === id);

  const bandLetter = (rehearsal) => {
    if (rehearsal.bandId && bandById(rehearsal.bandId)) {
      return bandById(rehearsal.bandId).band_letter;
    }
    return null;
  };

  const formattedBandLetter = (rehearsal) => {
    if (!rehearsal.bandId) {
      return '-';
    }
    return bandLetter(rehearsal);
  };

  const tableCellRehearsal = (date, time) => {
    const rehearsal = getRehearsal(date.id, time.id);
    const isDuplicate =
      typeof duplicateBandByDate(rehearsal.dateId, rehearsal.bandId, rehearsal.id) !== 'undefined';

    return (
      <TableCell key={`rehearsal-${date.id}-${time.id}`} align="center">
        {cellText(formattedBandLetter(rehearsal), isDuplicate)}
      </TableCell>
    );
  };

  const concertDetails = (event) => (
    <>
      <Typography variant="body" component="p">
        Concert
      </Typography>
      {cellText(`Time: ${event.time}`)}
      <br />
      <br />

      {event.eventType !== 'regular'
        ? cellText(StringUtils.humanize(event.eventType))
        : cellText('Regular (parents and school attending)')}
      <br />
      <br />

      {cellText(
        `Bands: ${
          event.onlyForSpecificBands
            ? _.compact(event.bandIds.map((id) => bandById(id)?.band_letter)).join(' ')
            : 'All'
        }`
      )}
      <br />
      <br />

      {cellText(event.note)}
    </>
  );

  const allDayEventsContent = (event) => {
    if (event.concert) return concertDetails(event);
    return cellText(event.note);
  };

  const tableCellAllDay = (date, index) => {
    if (index && index !== 0) return null;

    const event = eventById(date.id);

    return (
      <TableCell key={`all-day-${date.id}`} rowSpan={rehearsalTimes.length} align="center">
        {allDayEventsContent(event)}
      </TableCell>
    );
  };

  const tableCell = (date, time, index) =>
    date.type === 'rehearsal' ? tableCellRehearsal(date, time) : tableCellAllDay(date, index);

  const tableRow = (time, index) => {
    return (
      <TableRow key={`timetable-row-${time.id}`}>
        <TableCell align="center">{time.time}</TableCell>
        {allDates.map((date) => tableCell(date, time, index))}
      </TableRow>
    );
  };

  const tableRows = () => {
    if (rehearsalTimes.length > 0) {
      return <TableBody>{rehearsalTimes.map((time, index) => tableRow(time, index))}</TableBody>;
    }

    // If the date range contains no rehearsals we need to render only one row
    return (
      <TableBody>
        <TableRow key="timetable-row-only-all-day-events">
          <TableCell
            align="center"
            sx={{ height: 'fit-content', padding: allDates.length ? 'inherit' : 0 }}>
            {allDates.length ? null : (
              <ContentMissingPlaceholder
                icons={[calendarIcon]}
                text="No rehearsals or events are scheduled"
              />
            )}
          </TableCell>
          {allDates.map((date) => tableCellAllDay(date))}
        </TableRow>
      </TableBody>
    );
  };

  const displayDate = (date) => {
    let warningDate = false;
    const dateIsNotUsualTTDate =
      core.display_day_time_slot &&
      DateUtils.formattedDateParts(date)[0] !== core.display_day_time_slot.replace(/ .*/, '');

    if (dateIsNotUsualTTDate) warningDate = true;

    return (
      <span>
        {secondaryCellText(DateUtils.formattedDateParts(date)[0], warningDate)}
        <br />
        <Typography variant="bodyBold" color="paintItBlack.main">
          {DateUtils.formattedDateParts(date)[1]}
        </Typography>
        <br />
        {secondaryCellText(DateUtils.formattedDateParts(date)[2])}
      </span>
    );
  };

  const tableHeaderCell = (date) => {
    return (
      <TableCell key={`date-key-${date.id}-${date.type}`} align="center" sx={{ px: 8, py: 4 }}>
        {displayDate(date.date)}
      </TableCell>
    );
  };

  const colGroup = () => (
    <colgroup>
      <col style={{ width: colWidth }} />

      {allDates.map((date) => (
        <col style={{ width: colWidth }} key={`date-key-${date.id}-${date.type}`} />
      ))}
    </colgroup>
  );

  const tableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell align="center" sx={{ px: 8, py: 4 }}>
          {allDates.length ? (
            <Typography variant="bodyBold">Bands Rotation</Typography>
          ) : (
            <Typography variant="bodyBold">{`
            ${DateUtils.formattedDateParts(dateRange[0]).join(' ')}
            -
            ${DateUtils.formattedDateParts(dateRange[1]).join(' ')}`}</Typography>
          )}
        </TableCell>
        {allDates.map((date) => tableHeaderCell(date))}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer
      ref={targetRef}
      component={Box}
      sx={{
        ...styles.tableContainer,
        overflowX: { xs: 'auto', md: moreThanSevenDates ? 'auto' : 'hidden' }
      }}>
      <Table sx={styles.table} aria-label="spanning table">
        {colGroup()}
        {tableHead()}
        {tableRows()}
      </Table>
    </TableContainer>
  );
}

export default Timetable;

const styles = {
  tableContainer: { height: '100%', width: '100%' },
  table: {
    width: '100%',
    height: '100%',
    tableLayout: 'fixed'
  }
};
