/* eslint-disable react/prop-types */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '@rocksteady-music-school/rms-ui';
import emailTTSection from './EmailTTSection';
import updateEmailsSection from './UpdateEmailsSection';
import { useUserData } from '../../contexts/UserContext';

function EmailTimetableModal({
  timetableId,
  timetableDateParams,
  setEmailModalVisible,
  emailAddressesFromDb,
  setTimetableData
}) {
  const { isAdmin } = useUserData();

  const [unsavedChangesModalVisible, setUnsavedChangesModalVisible] = useState(false);
  const allAddresses = emailAddressesFromDb?.split(', ');

  const existingListOfEmailsWithIds = allAddresses
    ? allAddresses.map((addr) => ({ id: uuidv4(), email: addr }))
    : [{ id: uuidv4(), email: '' }];

  const [updatedListOfEmailsWithIds, setUpdatedListOfEmailsWithIds] = useState(
    existingListOfEmailsWithIds
  );

  useEffect(() => {
    setUpdatedListOfEmailsWithIds(existingListOfEmailsWithIds);
  }, [emailAddressesFromDb]);

  const noUnsavedChanges = _.isEqual(
    _.compact([...updatedListOfEmailsWithIds].map(({ email }) => email)),
    _.compact(existingListOfEmailsWithIds.map(({ email }) => email))
  );

  const handleClose = () => {
    if (noUnsavedChanges) {
      setEmailModalVisible(false);
    } else {
      setUnsavedChangesModalVisible(true);
    }
  };

  const emailOnDemandModalContent = () =>
    emailTTSection({ timetableId, timetableDateParams, setEmailModalVisible });

  const updateEmailsModalContent = () =>
    updateEmailsSection({
      timetableId,
      noUnsavedChanges,
      updatedListOfEmailsWithIds,
      setUpdatedListOfEmailsWithIds,
      setTimetableData,
      setEmailModalVisible,
      setUnsavedChangesModalVisible,
      unsavedChangesModalVisible
    });

  const modalContent = () => {
    if (isAdmin) return [emailOnDemandModalContent(), updateEmailsModalContent()];

    return [emailOnDemandModalContent()];
  };

  return <Modal onClose={handleClose} open modalEntities={modalContent()} />;
}

export default EmailTimetableModal;
