import { Typography } from '@mui/material';
import contactDetails from '../../consts/contactDetails';

export const noEnrollments = (
  <>
    <Typography variant="bodyBold" component="p" color="mellowYellow.140" marginBottom={16}>
      You currently don&apos;t have any pupils enrolled through match-funding.
    </Typography>
    <Typography variant="body" component="p" marginBottom={16}>
      To register a pupil, please click the register button below and complete the form.
    </Typography>
  </>
);

export const withEnrollments = (
  <Typography variant="body" component="p">
    Below are the details of the pupils you currently have listed under the match-funding scheme. To
    register an additional pupil, please click the register button below and complete the form. If
    you would like to edit or amend a child, please contact{' '}
    <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
      <Typography variant="bodyLink">the Schools Hotline</Typography>
    </a>{' '}
    or call us at{' '}
    <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
      <Typography variant="bodyLink">{contactDetails.SCHOOLS_TEL}</Typography>
    </a>{' '}
    to help.{' '}
  </Typography>
);
