/* eslint-disable class-methods-use-this */

import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class TimetableAPI {
  getTTData = (id, params) =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/timetables/${id}.json`,
      config: { params }
    });

  updateTTData = (id, data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/timetables/${id}.json`,
      data
    });

  getBandsData = (id) =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/timetables/${id}/bands.json`
    });

  getTTPdf = (id, params) =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/my/timetable/${id}`,
      config: { params }
    });

  emailTTPdf = (id, config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/timetables/${id}/email_pdf`,
      config
    });
}

export default new TimetableAPI();
