/* eslint-disable no-use-before-define */
import { Alert, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button } from '@rocksteady-music-school/rms-ui';
import paths from '../consts/paths';
import contactDetails from '../consts/contactDetails';
import UserAPI from '../api/UserAPI';
import AuthContainer from '../components/AuthContainer';

export default function SignUpConfirmationPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { registrationResponse, firstName, email } = location.state || {};

  // If the user gets here by typing the path directly, redirect them to the sign-up page
  if (!registrationResponse) navigate(paths.SIGN_UP);

  const verificationRequired = !registrationResponse?.verified;

  const [passwordRecentlySent, setPasswordRecentlySent] = useState(false);

  const [animationSlidesIn, setAnimationSlidesIn] = useState(true);

  const [slideInRef] = useOutletContext();

  const onExited = () => navigate(paths.LOG_IN);

  const handleNavigatingBack = () => {
    setAnimationSlidesIn(false);
  };

  const [error, setError] = useState(null);

  const passwordSentRecentlyMsg =
    'An email with the link to set your password has recently been sent to you. Please wait at least 10 minutes before requesting a new one.';

  const sendPasswordEmail = async () => {
    setPasswordRecentlySent(false);
    try {
      await UserAPI.setPassword({ data: { password: { email } } });
      enqueueSnackbar('Your password setting instructions should be with you shortly!', {
        variant: 'success'
      });
      setPasswordRecentlySent(true);
    } catch (err) {
      const errorMsg = err.response?.data?.error || err.message;
      setError(errorMsg);
    }
  };

  const renderAlert = (type, message) => (
    <Alert severity={type} sx={{ mt: 15 }}>
      {message}
    </Alert>
  );

  const confirmationHeading = () => (
    <Typography variant="h1" sx={styles.heading}>
      {verificationRequired ? `Thank you for signing up, ${firstName}` : 'Email sent'}
    </Typography>
  );

  const confirmationDescription = () => {
    if (verificationRequired) {
      return (
        <Typography variant="body">
          We are working on your registration request and will be in touch soon to get a few more
          details from you to finalise your access.
          <br />
          <br />
          If you have any questions, please don&apos;t hesitate to{' '}
          <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
            <Typography variant="bodyLink">contact our Schools Support team</Typography>
          </a>
          .
        </Typography>
      );
    }
    return (
      <Typography variant="body" component="p">
        Thank you for signing up to the Schools Portal. Your account has been successfully created.
        <br /> <br />
        We have sent you a confirmation email about your login details. It can take up to 5 minutes
        for your email to arrive. The link is only valid for the next 12 hours. If you can&apos;t
        find the email, please check your junk folder.
        <br /> <br />
        Still haven&apos;t received the email?{' '}
        <Typography onClick={sendPasswordEmail} variant="bodyLink">
          Send again
        </Typography>
      </Typography>
    );
  };

  const confirmation = () => (
    <Box display="flex" flexDirection="column">
      {confirmationHeading()}
      {confirmationDescription()}
      {passwordRecentlySent && renderAlert('warning', passwordSentRecentlyMsg)}
      {error &&
        renderAlert(
          'error',
          `An error occurred: ${error}. Please contact us if the problem persists.`
        )}
      <Button
        onClick={handleNavigatingBack}
        label="Return to Sign In"
        color="secondary"
        sx={{ marginTop: '24px', width: 'fit-content' }}
      />
    </Box>
  );

  return registrationResponse ? (
    <AuthContainer
      animationSlidesIn={animationSlidesIn}
      containerRef={slideInRef}
      onExitedCallback={onExited}>
      {confirmation()}
    </AuthContainer>
  ) : null;
}

const styles = {
  heading: { mb: 24, mt: 50 }
};
