/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import { Typography, Box, Skeleton } from '@mui/material';
import ClippedImgCardContainer from './ClippedImgCardContainer';
import concertBg from '../../assets/images/next_concert.png';

function CalendarIconContainer({ children }) {
  return (
    <Box
      width={60}
      height={60}
      sx={styles.calendarIconContainer}
      bgcolor="white"
      borderRadius="15px">
      <Box
        m={5}
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        {children}
      </Box>
    </Box>
  );
}

function LoadingConcertCard() {
  return (
    <Skeleton
      width="100%"
      height="100%"
      variant="rectangular"
      sx={{ borderRadius: '10px', gridColumn: { xs: 'span 2', sm: 'span 1' } }}
    />
  );
}

export default function ConcertCard({ nextConcertDate, loading }) {
  const calendarDay = () => (
    <Typography variant="h2" textAlign="center" color="primary.main" sx={styles.calendarDay}>
      {new Date(nextConcertDate).toLocaleString('en-GB', { day: 'numeric' })}
    </Typography>
  );

  const calendarMonth = () => (
    <Typography
      variant="bodyBold"
      lineHeight={1}
      textAlign="center"
      color="paintItBlack.40"
      textTransform="uppercase">
      {new Date(nextConcertDate).toLocaleString('en-GB', { month: 'short' })}
    </Typography>
  );

  const calendarIcon = () => (
    <CalendarIconContainer>
      {calendarDay()}
      {calendarMonth()}
    </CalendarIconContainer>
  );

  const scheduledConcert = () => (
    <ClippedImgCardContainer
      bgImg={concertBg}
      clipPath="polygon(0 0, 24% 0, 35% 100%, 0% 100%)"
      bgcolor="yellowSubmarine.main"
      justifyContent="space-between">
      {calendarIcon()}
      <Typography variant="h4" sx={{ zIndex: 1 }} width="100%" textAlign="end">
        Your next concert
      </Typography>
    </ClippedImgCardContainer>
  );

  const noConcert = () => (
    <ClippedImgCardContainer
      bgcolor="paintItBlack.10"
      flexDirection="column"
      p={7}
      alignItems="center"
      justifyContent="center">
      <Box>
        <Typography
          variant="bodyBold"
          sx={{ whiteSpace: 'pre-line', display: 'flex', alignItems: 'center' }}
          color="fadeToGrey.main">
          Your next concert date will appear here when booked
        </Typography>
        <Typography variant="bodyBold" color="paintItBlack.40" fontSize={12} lineHeight="16px">
          Please talk to your band leader.
        </Typography>
      </Box>
    </ClippedImgCardContainer>
  );

  if (loading) return <LoadingConcertCard />;

  return nextConcertDate ? scheduledConcert() : noConcert();
}

const styles = {
  calendarIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '1 / 1',
    zIndex: 1,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  calendarDay: {
    fontSize: '2.5rem',
    lineHeight: 0.9,
    letterSpacing: '-3px',
    textIndent: '-3px'
  }
};
