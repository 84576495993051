/* eslint-disable react/prop-types */
import { Typography, Box, Grid } from '@mui/material';
import { Modal } from '@rocksteady-music-school/rms-ui';
import contactDetails from '../../consts/contactDetails';

function DBSModal({ open, onClose, blDBSInfo, blsDBSInfo, loading }) {
  const modalTextLine = (text, variant) => (
    <Typography variant={variant} display="block" mt={8}>
      {text}
    </Typography>
  );

  const dpaText = () => (
    <Box mb={16}>
      <Typography variant="bodyBold" component="p" mb={16}>
        Please be aware that DBS numbers are considered sensitive and are treated with the same
        level of care and protection as special category personal data under the UK GDPR.
      </Typography>
      <Typography variant="body" component="p" mb={16}>
        Rocksteady Music School is making this data available in order to help schools carry out DBS
        checks of our staff where needed for their legal and safeguarding obligations.
      </Typography>
      <Typography variant="body" component="p">
        Please ensure when using this data it is kept safe and secure, not shared with anyone who
        does not need access and kept only for as long as needed/relevant in accordance with your
        school&apos;s data retention schedule. Please notify us immediately if this data may have
        been compromised in any way either by email (
        <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
          <Typography variant="bodyLink">{contactDetails.SCHOOLS_EMAIL}</Typography>
        </a>
        ) or by telephone (
        <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
          <Typography variant="bodyLink">{contactDetails.SCHOOLS_TEL}</Typography>
        </a>
        ).
      </Typography>
    </Box>
  );

  const bandLeaderDBSDisplay = () => (
    <Box>
      <Typography variant="h5">Band Leader</Typography>
      <Grid container>
        <Grid item xs={6}>
          <Box mr={50}>
            {modalTextLine('Band Leader Name on DBS:', 'body')}
            {modalTextLine('DBS Reference Number:', 'body')}
            {modalTextLine('DBS Issue Date:', 'body')}
            {modalTextLine('DBS Certification Notifications:', 'body')}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            {modalTextLine(blDBSInfo?.name_on_dbs_certificate || '-', 'bodyBold')}
            {modalTextLine(blDBSInfo?.dbs_ref_no || '-', 'bodyBold')}
            {modalTextLine(blDBSInfo?.dbs_issue_date || '-', 'bodyBold')}
            {modalTextLine(blDBSInfo?.dbs_cert_notifications || '-', 'bodyBold')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const bandLeaderSupportDBSDisplay = () => (
    <Box mt={40}>
      <Typography variant="h5">Band Leader Manager</Typography>
      <Grid container>
        <Grid item xs={6}>
          <Box mr={50}>
            {modalTextLine('Band Leader Manager Name on DBS:', 'body')}
            {modalTextLine('DBS Reference Number:', 'body')}
            {modalTextLine('DBS Issue Date:', 'body')}
            {modalTextLine('DBS Certification Notifications:', 'body')}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            {modalTextLine(blsDBSInfo?.name_on_dbs_certificate || '-', 'bodyBold')}
            {modalTextLine(blsDBSInfo?.dbs_ref_no || '-', 'bodyBold')}
            {modalTextLine(blsDBSInfo?.dbs_issue_date || '-', 'bodyBold')}
            {modalTextLine(blsDBSInfo?.dbs_cert_notifications || '-', 'bodyBold')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const modalContent = () => {
    return (
      <Box>
        {dpaText()}
        {bandLeaderDBSDisplay()}
        {bandLeaderSupportDBSDisplay()}
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      modalEntities={[
        {
          title: 'DBS Details',
          content: modalContent(),
          loading
        }
      ]}
    />
  );
}

export default DBSModal;
