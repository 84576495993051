/* eslint-disable react/prop-types */
import { v4 as uuidv4 } from 'uuid';
import { Box, Card, CardContent, Chip, Grid, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';

export default function PermissionsCard({
  roleName,
  abilities,
  isCurrent = false,
  isSelected = false,
  tooltip = false,
  setIsSelected
}) {
  const selectRole = () => setIsSelected(isSelected ? null : roleName);

  const theme = useTheme();

  const backgroundColor = tooltip ? 'transparent' : 'whiteWedding.main';
  const borderWidth = tooltip ? 0 : 1;
  const borderColor = isSelected ? 'primary.main' : null;
  const fontColor = tooltip ? 'whiteWedding.main' : 'paintItBlack.main';
  const checkColor = tooltip ? theme.palette.whiteWedding.main : theme.palette.greenOnions[140];

  const renderAbilitiesList = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {abilities.map((abilityText) => (
        <Box key={uuidv4()} sx={{ display: 'flex', marginBottom: 4, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faCheck} fontSize={20} color={checkColor} />
          <Typography sx={{ marginLeft: 16, color: fontColor }} variant="body">
            {abilityText}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const renderSelectButton = () => (
    <Button
      label={isSelected ? 'Selected' : 'Select'}
      outlined={!isSelected}
      onClick={selectRole}
    />
  );

  const renderCurrentPermissionChip = () => (
    <Chip
      label="Current Permission"
      sx={{
        bgcolor: 'greenOnions.main',
        fontSize: 14,
        fontWeight: 700,
        color: 'paintItBlack.main',
        lineHeight: '16px'
      }}
    />
  );

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        borderWidth,
        borderColor,
        backgroundColor
      }}>
      <CardContent sx={{ paddingTop: 24, paddingLeft: 24, paddingRight: 24 }}>
        <Grid container spacing={8} direction="row">
          <Grid item xs={12} md={8}>
            <Typography sx={{ color: fontColor }} variant="h5">
              {`${StringUtils.upperCaseFirstLetter(roleName)} Access`}
            </Typography>
            <Box display="flex" flexDirection="column" sx={{ paddingTop: 16 }}>
              {renderAbilitiesList()}
            </Box>
          </Grid>
          {!tooltip && (
            <Grid container item xs={12} md={4} alignItems="flex-start" justifyContent="flex-end">
              {isCurrent ? renderCurrentPermissionChip() : renderSelectButton()}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
