const testimonials = Object.freeze({
  SIGN_IN: [
    {
      text: 'These lessons provide experiences beyond the classroom and give pupils opportunities to play instruments which they might not otherwise have. We truly hope that skills learnt can be carried through to their next phase of education. ',
      author: 'Kathryn Forsyth, Acting Headteacher, Brookhouse Primary'
    },
    {
      text: 'We have found that the confidence built as musicians translates to confidence throughout the curriculum.',
      author: 'Adam Khan, Headteacher, Helsby Hillside Primary School'
    },
    {
      text: 'We love our Friday music lessons and think Tommy Two Sticks is very cool!',
      author: 'Eve Andrew, Teacher, Wyken Croft Primary School'
    }
  ],
  FGF: [
    {
      text: 'The lessons are a fantastic and fun way to access the music curriculum. They help teachers who usually do not feel confident in teaching music to their year group. It makes music feel fun again for teachers and the children!',
      author: "Lucie Shand, Alderman Cogan's C of E Primary Academy"
    },
    {
      text: 'The children are really enjoying the Feel Good Friday lessons and look forward to them every week.',
      author: 'Amanda Glynn, Wren Park Primary School'
    },
    {
      text: "Feel Good Friday is a blessing! The children love it, it's so great to have some musical experts sharing knowledge in a fantastic, child-friendly way.",
      author: 'Ashley Ward, Oliver Goldsmith Primary School'
    }
  ]
});

export default testimonials;
